.b-send-to-cs-control {
  position: relative;

  &__send_to_customer_control {
    background: transparent;
    box-shadow: none;
    cursor: pointer;
    display: inline-block;
    text-decoration: underline;
    font-size: rem(12px);
    margin-top: rem(10px);

    &:hover {
      text-decoration: none;
    }
  }

  &__send_to_customer_loader {
    position: relative;
    left:rem(-24px);
  }

  &__send_to_customer_date {
    color: $F100;
    margin: rem(5px) 0 0 0;
    font-size: rem(12px);
    font-style: italic;
  }
}