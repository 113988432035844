.b-orders-page {
  &__in_table_status {
    margin: 0;
  }

  &__widget_btn_loader {
    position: relative;
    left:rem(-24px);
    top: rem(10px);
  }
}