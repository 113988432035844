.b-page-control-items {
  transition: margin-left 0.3s ease;
  text-align: left;

  &--folded_open {
    margin-left: rem(218px);
  }
  &__body{
    flex-grow: 1;
  }
  &__body_item {
    text-align: center;
  }

  &__body_item--search {
    padding: rem(5px) 0 0 0;
  }
}