@import './variables';

html {
  width: 100%;
  height: 100%;
}

body {
  font-family: $main-font-family;
  background: $main-bg-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  * {
    box-sizing: inherit;
  }
}

body,
#root {
  color: $F800;
  position: relative;
  width: inherit;
  height: inherit;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px $main-color inset;
}

.b-flex {
  display: flex;
  flex-wrap: nowrap;

  &--reverse {
    flex-direction: row-reverse;
  }

  &--wrap {
    flex-wrap: wrap;
  }
}

.uppercase {
  text-transform: uppercase;
}

.hidden {
  display: none;
}

/* Components */
@import '../components/Header/styles/Header';
@import '../components/Returnado/styles/Returnado';
@import '../components/ServerErrorNotifier/styles/ServerErrorNotifier';
@import '../components/ServerSuccessNotifier/styles/ServerSuccessNotifier';
@import '../components/Login/styles/Login';
@import '../components/ResetPassword/styles/ResetPassword';
@import '../components/LeftSideBar/styles/LeftMenu';
@import '../components/RightMenu/styles/RightMenu';
@import '../components/PageHeader/styles/PageHeader';
@import '../components/MainNavigation/styles/MainNavigation';
@import '../components/PageControlItems/styles/PageControlItems';
@import '../components/PageFiltersItems/styles/PageFiltersItems';
@import '../components/CustomTable/styles/CustomTable';
@import '../components/CustomTranslation/styles/CustomTranslation';
@import '../components/Search/styles/Search';
@import '../components/ReturnDescription/styles/ReturnDescription';
@import '../components/ConfirmBeforeUpdateDialog/styles/ConfirmBeforeUpdateDialog';
@import '../components/ReturnCommentsAreas/styles/ReturnCommentsAreas';
@import '../components/DashboardMetrics/styles/DashboardsMetrics';
@import '../components/FixedFeeSelector/styles/FixedFeeSelector';
@import '../components/FixedPercentageSelector/styles/FixedPercentageSelector';
@import '../components/DropZone/styles/DropZone';
@import '../components/ReclamationImagesGallery/styles/ReclamationImagesGallery';
@import '../components/ReturnsTable/styles/ReturnsTable';
@import '../components/ReturnEventLogPopup/styles/b-return-event-log-popup';

/* Containers */
@import '../containers/Loader/styles/Loader';
@import '../containers/CustomButton/styles/CustomButton';
@import '../containers/Loader/styles/Loader';
@import '../containers/PageContent/styles/PageContent';
@import '../containers/PartialApproval/styles/PartialApproval';
@import '../containers/SendToCustomeServiceControl/styles/SendToCustomeServiceControl';
@import '../containers/TooltipWrapper/styles/TooltipWrapper';

/* Hooks */
@import '../hooks/PageNotFound/styles/PageNotFound';

/* High Order Component */
@import '../HOC/AppLayout/styles/AppLayout';

/* Pages */
@import '../pages/Inbound/styles/Inbound';
@import '../pages/Inbound/styles/InboundItem';
@import '../pages/Returns/styles/ReturnsItem';
@import '../pages/Orders/styles/Orders';
@import '../pages/Returns/styles/Returns';
@import '../pages/Security/styles/Security';
@import '../pages/ItemData/styles/itemDataReport';
@import '../pages/ReturnData/styles/returnDataReport';
@import '../pages/Analytics/styles/Analytics';
@import '../pages/Management/styles/Management';

/* GENERAL */
@import './b-page/b-page';
@import './b-pagination/b-pagination';
@import './b-mismatch-tooltip/b-mismatch-tooltip';

/* Overrides */
@import './overrides';