.b-navigation-menu {

  &__header {
    padding: rem(15px) rem(10px) 0 rem(7px);
    text-align: left;
  }

  &__logo {
    width: rem(47px);
    max-height: rem(60px);
    display: block;
    margin: 0 auto
  }

  &__logo--toteme_toteme {
    position: relative;
    background: $white;
    top: rem(-7px);
  }

  &__logo--magento_bjornborg,
  &__logo--royal_design_royal_design {
    width: 100%;
    padding: rem(8px) rem(5px);
  }

  &__logo--centra_asket,
  &__logo--magento_hh {
    width: 100%;
    max-width: rem(70px);
    padding: 0 0 rem(14px) 0;
  }

  &__bottom_logo {
    width: rem(47px);
    display: inline-block;
    margin: 0 auto 0 auto;
  }

  &__bottom_logo_text {
    display: none;
    position: absolute;
    height: rem(40px);
  }

  &__bottom_logo_wrapper {
    position: absolute;
    transition: width 1s linear;
    bottom: rem(20px);
  }

  &__bottom_logo_wrapper {
    width: 100%;
  }

  &__bottom_logo_wrapper--open {
    .b-navigation-menu__bottom_logo {
      margin-left: rem(37px);
    }

    .b-navigation-menu__bottom_logo_text {
      display: inline-block;
      left: rem(102px);
    }
  }

  &__item {
    height : rem(40px);
    width : calc(100% - 10px) !important;
    border-radius : 0 rem(20px) rem(20px) 0 !important;
    padding-right : rem(12px) !important;
    white-space: nowrap;
  }

  &__item_icon {
    padding-right: 0;
    margin: 0 rem(6px) 0 rem(1px);
  }
}