.b-server-success-notifier {
  &__message {
    display: flex;
    align-items: center;
  }

  &__title {
    text-align: left;
  }

  &__text {
    padding: rem(4px) 0 0 rem(10px);
  }

  &__list {
    text-align: left;
  }
}