.b-search {
  position: relative;

  input {
    padding-top: rem(6px);
    padding-bottom: rem(6px);
  }

  &__item {

  }

  &__p:first-child {
    width: 100%;
    margin: 0 rem(10px) 0 0;

    > div {
      margin: 0;
    }

  }

  &__search_clear {
    position: absolute;
    top: 50%;
    cursor: pointer;
    margin-top: -0.5em;
  }

  &__search_clear--with_search_control {
    right: rem(105px);
  }

  &__submit {
    padding: rem(9px) rem(16px) !important;
    top: rem(1px);
  }

}

.b-search--returns {
  margin: rem(15px) 0 0 0;
  > form input {
    box-sizing: border-box;
    padding-right: rem(24px);
  }
}

.b-search--orders {
  margin: 0 0 rem(10px) 0;

  &__p:first-child {
    margin: 0 rem(10px) 0 0;
  }

}