.b-fixed-percentage-selector {
  position: relative;
  top: rem(16px);
  width: rem(150px);
  min-width: rem(150px) !important;
  max-width: rem(150px);

  &--inbound {
    top: auto;
  }

  &__control {
    width: 100%;
    div[class$="-container"] {
      border-radius: rem(5px);

      div[class$="-control"] {
        background: transparent;
        border: 0;
        padding: 0 rem(4px);
        min-height: rem(32px);

        > div:first-child {
          visibility: hidden;
        }

        > div:last-child {
          background: $main-color;
          border: rem(1px) solid $F80;
          border-radius: rem(5px);

          div[class$="-indicatorContainer"] {
            margin: 0;
          }
        }

      }

      div[class$="-placeholder"] {
        text-transform: none !important;
        font-weight: normal !important;
        font-size: inherit !important;
      }

      div[class$="-indicatorContainer"] {
        color: $main-text-color;
      }
    }
  }

  &__control--disabled {
    div[class$="-container"] {
      div[class$="-indicatorContainer"] {
        color: $disabledControlsColor;
      }
    }
  }

  &__hybrid {
    position: relative;
    width: rem(150px);
  }

  &__result_diminished_price {
    border: rem(1px) solid $F80;
    padding: rem(6px) rem(2px) !important;
    font-size: rem(16px);
    font-weight: 600;
    border-radius: rem(4px);
    max-width: rem(100px);
    outline: none;

    &:disabled {
      background: $main-color;
      opacity: 0.8;
    }
  }

  &__control_input {
    position: absolute !important;
    left: 0;
    width: calc(100% - 25px);
    z-index: 1;
    background: $main-color;
    border-radius: rem(4px);

    > div:first-child {
      padding-right: rem(5px)  !important;
      border: rem(1px) solid $F80;
      border-radius: rem(4px);
      &:after,
      &:before {
        display: none;
      }
    }

    fieldset {
      border: 1px solid $F80 !important;
    }

    input {
      padding: rem(5px) 0 rem(5px) rem(5px) !important;
      margin-top: rem(1px);
      background: $main-color;
      font-weight: 600;
    }

    input+div {
      position: relative;
      &:before {
        display: block;
        position: absolute;
        content: '%';
        left: rem(-17px);
        font-weight: 600;
      }
    }

    input::placeholder {
      font-size: rem(12px);
      font-weight: normal;
    }
  }

  &__control_input--simple {
    position: absolute !important;
    left: 0;
    width: calc(100% - 25px);
    z-index: 1;
    background: $main-color;
    border-radius: rem(4px);
    border: rem(1px) solid $F80;
    font-size: rem(16px);
    padding: rem(6px) rem(2px) !important;
  }

  &__control_input--no_dropdown {
    position: relative !important;
    width: 100%;
  }

  &__clear_control {
    cursor: pointer;
  }

  &__equal_sign,
  &__reduce_sign {
    margin: 0 rem(5px);
    font-weight: 600;
    height: rem(24px);
    padding: rem(7px) 0 0 0;
  }

  &__reduce_sign,
  &__equal_sign {
    padding: rem(7px) rem(5px);
    position: relative;
    font-size: rem(16px);
  }

  &__result {
    font-style: italic;
    position: relative;

    &:after {
      background: $main-text-color;
      position: absolute;
      content: '';
      height: rem(1px);
      left: 0;
      right: 0;
      bottom: rem(-2px);
    }
  }

  &__result_wrapper,
  &__control_wrapper {
    position: relative;
    min-width: rem(100px);
  }

  &__input_label,
  &__default_product_price_label,
  &__result_label {
    position: absolute;
    font-size: rem(16px);
    font-family: $main-font-family;
    color: $returnAdditionalLabelColor;
    white-space: nowrap;
    transform: $controlLabelsTransform;
    font-weight: 600;
    top: rem(-19px);
    left: rem(-13px);
  }

  &__input_label--disabled,
  &__result_label--disabled {
    color: $disabledControlsColor;
  }

  &__default_product_price {
    font-size: rem(16px);
    font-weight: 600;
    position: relative;
    top: rem(7px);
    white-space: nowrap;
  }

  &__default_product_price_label {
    top: rem(-26px);
    left: rem(-6px);
  }

}