/* General colors */

$F50  : #ECECEE;
$F80  : #ebebeb;
$F100 : #C5C6CB;
$F200 : #9EA1A9;
$F300 : #7D818C;
$F400 : #5C616F;
$F500 : #3C4252;
$F600 : #353A48;
$F700 : #2D323E;
$F800 : #262933;
$F900 : #1E2129;
$A100: #C5C6CB;
$A200: #9EA1A9;
$A400: #5C616F;
$A700: #2D323E;
$dark: rgb(48, 48, 48);
$blue: #039be5;
$light: #FFFFFF;
$white: #FFFFFF;
$lightTextColor: #f5f5f5;
$black: #010101;

$main-color: $white;
$main-bg-color: #F5F5F5;
$main-first-color: #009E86;
$main-second-color: #1A3865;
$main-text-color: #555555;
$main-navigation-color: #303030;
$login-input-bg: rgb(232, 240, 254);
$returnEventLogBackgroundColor: #e0e0e0;


$inboundRejectColor: rgb(250,101,50);
$inboundRemoveColor: rgb(218,33,39);
$inboundArrivedColor: rgb(0,158,134);
$inboundLightGrayColor: #e5e5e5;


$inboundMetricaItemScanned: #ff7474;
$inboundMetricaItemScannedInactive: rgba(255, 116, 116, 0.85);
$inboundMetricaItemOnTheWay: #d3ba2c;
$inboundMetricaItemOnTheWayInactive: rgba(211, 186, 44, 0.85);
$inboundMetricaItemFlagged: #101010;
$inboundMetricaItemFlaggedInactive: rgba(16, 16, 16, 0.85);
$inboundMetricaItemHandled: #00b842;
$inboundMetricaItemHandledInactive: rgba(0, 184, 66, 0.85);
$returnAdditionalLabelColor: rgba(0, 0, 0, 0.54);

$dashBoardCardOrangeColor: #ff973b;
$dashBoardCardGreenColor: #57c461;
$dashBoardCardBlackColor: #212121;

/* FUSE colors */
$main-desable-fuse-color: rgba(0, 0, 0, 0.01);
$main-fuse-gradient: linear-gradient(to right, $F700 0%, $F500 100%);

/* General fonts */
$main-font-family: "Roboto", "Helvetica", "Arial", sans-serif;

/* Return additional controls */
$disabledControlsColor: rgba(0, 0, 0, 0.38);
$controlLabelsTransform: translate(0, 1.5px) scale(0.75);

/* General Dimensions */
$min-width: 300px;


/* Functions */
@function rem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

/* Mixin */
@mixin fontSize($size) {
  font-size: $size; //Fallback in px
  font-size: rem($size);
}