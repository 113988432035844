.b-tooltip-wrapper {
  position: relative;
  display: inline-block;

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}