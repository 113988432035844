.b-item-data-report-page {

  font-family: "Open Sans";

  &__additional_header {
    padding: 11px 0 0 0;
    margin-bottom: 0;
    font-weight: 500;
    font-size: rem(112px);
    text-transform: uppercase;
  }

  &__description {
    font-size: rem(16px);
    margin: rem(10px) auto rem(25px) auto;
  }

  &__filters_section {
    margin: rem(10px) 0;
  }

  &__btn_wrapper {
    width: rem(200px);
    margin: rem(10px) auto;
  }

  &__counter {
    margin: 0 0 rem(10px);
  }
  &__flex-grow{
    flex-grow: 1;
  }
}