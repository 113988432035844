.b-partial-approval {

  min-width: rem(337px);

  &--returns {
    min-width: 100%;
  }

  &__list {
    border: 1px solid $F50;
    display: block;
    list-style-type: none;
    padding: 0 rem(5px) 0 0;
    text-align: left;
    margin: 0 auto;
    width: 98%;
  }

  &__arrived_indicator_item {
    display: inline-block;
    width: rem(18px);
    height: rem(18px);
    border: rem(1px) solid $inboundMetricaItemHandled;
    margin: rem(13px) rem(15px);
    background: $inboundMetricaItemHandled;
    border-radius: rem(2px);
  }

  &__list_item {
    text-align: left;
    display: inline-block;
  }

  &__list_item:last-child {
    padding: 0 0 0 rem(3px);
  }

  &__list_label {
    text-transform: uppercase;
    margin: 0 !important;

    > span:last-child {
      font-size: rem(12px);
    }
  }

  &__not_visible {
    visibility: hidden;
  }
}

.b-partial-approval--inbound {

  .b-partial-approval__list {
    background: $light;
    border: 1px solid $F50;
    list-style-type: none;
    padding: 0 rem(15px) 0 0;
    text-align: left;
    margin: rem(6px) 0 0 0;
    transition: top 1s linear;
    width: 100%;
  }

  .b-partial-approval__list_item {
    text-align: left;
    display: inline-block;
  }

  .b-partial-approval__list_label {
    text-transform: uppercase;
    margin-right: 0 !important;
    margin-left: rem(5px) !important;

    > div:first-child {
      margin: rem(8px);
    }

    > span:first-child {
      padding: rem(5px);
    }

    > span:last-child {
      font-size: rem(10px);
    }
  }

}

.b-partial-approval--inbound_expanded {
  .b-partial-approval__list {
    margin: rem(14px) 0 0 0;
  }
}

.b-partial-approval__arrived_indicator_item {
  display: inline-block;
  width: rem(18px);
  height: rem(18px);
  border: rem(1px) solid $inboundMetricaItemHandled;
  background: $inboundMetricaItemHandled;
  border-radius: rem(2px);
}

.b-partial-approval__arrived_indicator_item--red {
  border: rem(1px) solid $inboundRemoveColor;
  background: $inboundRemoveColor;
}

.b-partial-approval__arrived_indicator_item--yellow {
  border: rem(1px) solid $inboundMetricaItemOnTheWay;
  background: $inboundMetricaItemOnTheWay;
}