.b-management-page {

  &__subtitle {
    font-size: rem(18px);
    font-weight: 600;
  }

  &__add_user_control {
    position: relative;
    border: rem(2px) solid $main-first-color;
    padding: rem(3px) rem(15px) rem(2px) rem(30px);
    top: rem(-1px);
    color: $main-first-color;
    font-size: rem(14px);
    margin: 0 0 0 rem(10px);
    cursor: pointer;
    opacity: 0.9;

    &:hover {
      opacity: 1;
    }
  }

  &__add_user_control_icon {
    position: absolute;
    top: 50%;
    margin-top: rem(-11px);
    left: rem(4px);
  }

  &__tabs {
    margin: 0 0 rem(10px) 0;
  }

  &__tabs_item {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  &__tabs_item--middle {
    margin-left: rem(5px);
    margin-right: rem(5px);
    font-weight: 600;
  }

  &__tabs_item.active {
    font-weight: 600;
  }

  &__rotate {
    transform: rotate(45deg);
  }

  &__table_control {
    cursor: pointer;
    opacity: 0.8;
    position: relative;
    top: rem(-3px);

    &:hover {
      opacity: 1;
    }
  }

  &__table_control--own {
    cursor: auto;
    opacity: 1;
  }

  &__table_control--green {
    color: $inboundMetricaItemHandled;
    opacity: 1;
  }

  &__table_control--red {
    color: $inboundRemoveColor;
    opacity: 1;
  }

  &__popup_content {
    display: block;
    font-size: rem(14px);
  }

  &__popup_content_item {
    justify-content: space-between;
    margin: 0 0 rem(25px) 0;
  }


  &__popup_content_control_label {
    font-weight: 500;
    margin-right: rem(5px);
    color: $main-text-color;
  }

  &__popup_content_control_label,
  &__popup_content_control_input {
    display: inline-block;
  }

  &__popup_content_control_input {
    outline: none;
    border: rem(1px) solid $F100;
    padding: rem(2px) rem(5px);
    color: $main-text-color;

    &.error:focus,
    &.error {
      border-color: $inboundRemoveColor;
    }

    &:focus {
      border-color: $F200;
    }
    &::placeholder {
      font-style: italic;
    }
  }

  &__popup_content_item--block,
  &__popup_content_description {
    display: block;
  }

  &__popup_content_control_wrapper--single {
    flex-basis: 100%;
    text-align: left;
  }

  &__popup_content_description {
    font-style: italic;
    font-size: rem(12px);
  }

  &__popup_content_control_label--single {
    flex-basis: rem(68px);
  }

  &__popup_content_control_input--single {
    width: 100%;
  }

  &__popup_content_input_wrapper {
    position: relative;
  }

  &__popup_content_input_wrapper--single {
    display: block;
    flex-basis: calc(100% - 68px);
  }

  &__popup_content_error_text {
    position: absolute;
    bottom: rem(-23px);
    left: 0;
    font-size: rem(11px);
    color: $inboundRemoveColor;
  }

  &__popup_content_control_label {
    text-align: left;
  }

  &__popup_content_control_label--fixed_min_width {
    min-width: rem(67px);
  }

}