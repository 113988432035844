.b-inbound-item {
  background-color: $light;
  margin: rem(10px) 0 rem(20px) 0;
  border: rem(1px) solid $F50;
  border-radius: rem(5px);
  box-shadow: 0 rem(1px) rem(5px) 0 rgba(0,0,0,0.08), 0 rem(2px) rem(2px) 0 rgba(0,0,0,0.08), 0 rem(3px) rem(1px) rem(-2px) rgba(0,0,0,0.08);
  transition: background-color 0.2s linear;

  &--removed {
    border: rem(3px) solid $inboundRemoveColor;
  }

  &--rejected {
    border: rem(3px) solid $inboundRejectColor;
  }

  &--arrived {
    border: rem(3px) solid $inboundArrivedColor;
  }

  &__header {
    border-radius: rem(5px) rem(5px) 0 0;
  }

  &__head {
    background-color: transparent !important;
    box-shadow: none !important;
    border-bottom: 1px solid $F50;
    padding: 0 0 0 rem(16px);
    position: relative;
    z-index: 1;
  }

  &__body {
    font-size: rem(12px);
  }

  &__report_icon {
    opacity: 0.3
  }

  &__report_icon--active {
    opacity: 1;
  }

  &__accordion {
    font-size: rem(14px);
    background: transparent !important;
    box-shadow: none !important;
    border-top: rem(1px) solid $F80;

    &:before {
      background-color: transparent !important;
    }
  }

  &__accordion_control_panel {
    background-color: $light !important;
    border: rem(1px) solid $F50 !important;
    border-bottom: 0 !important;
    border-radius: rem(5px) rem(5px) 0 0 !important;

    & > div:first-child {
      align-items: center;
    }
  }

  &__accordion_control_panel--collapsed {
    border-bottom: rem(1px) solid $F50 !important;
    border-radius: rem(5px) !important;
  }

  &__accordion_details {
    display: block;
  }

  &__accordion_details_block {
    background-color: $light;
    margin: 0 rem(-16px) rem(10px);
    padding: 0 rem(22px);
    border: rem(1px) solid $F50;
    border-top: 0;
    border-radius: 0 0 rem(5px) rem(5px);
  }

  &__accordion_item {
    position: relative;
    margin: 0 auto;
    width: 100%;
    padding: 0 0 0 rem(40px);
    font-size: rem(12px);
  }

  &__accordion_left_icon {
    top: 50%;
    left: 8px;
    position: absolute;
    transform: translateY(-50%) rotate(0deg);
    color: rgba(0, 0, 0, 0.54);
  }

  &__counter {
    border-radius: 50%;
    font-family: 'Open Sans';
    background: $blue;
    color: $main-color;
    font-size: rem(14px);
    line-height: rem(43px);
    text-align: center;
    margin-right: rem(10px);
    width: rem(43px);
    height: rem(43px);
    position: absolute;
    left: rem(-25px);
    top: rem(-8px);
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__img {
    display: block;
    max-width: rem(120px);
    width: 100%;
  }

  &__filters {
    font-size: rem(13px);
    width: 90%;

    div[class$="-container"] {

      &:hover {
        background: transparent;
      }

      div[class$="-placeholder"],
      div[class$="-singleValue"] {
        color: $dark;
        text-transform: uppercase;
        font-weight: 600;
        font-size: rem(15px);
      }

      div[class$="-placeholder"] {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: calc(100% - 8px);
      }
    }

    .b-page-filter-items__items {
      text-align: center;
    }

    .b-page-filter-items {
      &__item--number {

        > div {
          border: rem(1px) solid $F80;
          background: $main-color;
          border-radius: rem(5px);
          &:before {
            display: none;
          }

          > input {
            padding: rem(6px) 0 rem(5px) rem(3px);
          }
        }

      }
    }
  }

  &__block {

  }

  &__subblock {
    text-align: left;
    font-size: rem(14px);
  }

  &__wrapper {

  }

  &__textarea {
    display: block;
    width: 100%;
    margin: 0 auto;
    height: rem(75px);
    padding: rem(5px) rem(7px);
    resize: none;
  }

  &__textarea::placeholder {
    color: $F100;
    font-style: italic;
  }

  &__common_info {
    padding: rem(20px) 0 0 0;
  }

  &__common_info_item {
    margin: rem(3px) 0;
  }

  &__message_wrapper {
    display: block;
    padding: rem(8px) rem(24px) rem(24px) rem(24px);
  }

  &__message {
    font-size: rem(12px);
    display: block;
    width: 100%;
  }

  &__message_image {

  }

  &__bottom_pusher {
    height: rem(15px);
  }

  &__message_img {
    display: block;
    max-width: 100%;
    max-height: 7.5rem;
    margin: 0 auto;
  }

  &__message_text {
    color: $dark;
    padding: rem(10px);
    text-align: left;
    font-style: italic;
    font-weight: 600;
    height: rem(100px);
  }

  &__textarea,
  &__message_text {
    border: 1px solid $inboundLightGrayColor;
    border-radius: 0 0 rem(5px) rem(5px);
  }

  &__btn_wrapper {
    margin-block-start: rem(16px);
    margin-block-end: rem(16px);
  }

  &__comment_label {
    display: block;
    margin: rem(20px) 0 rem(10px) 0;
    font-weight: 600;
    text-align: left;
  }

  &__send_to_customer_control {
    background: transparent;
    box-shadow: none;
    cursor: pointer;
    display: inline-block;
    text-decoration: underline;
    font-size: rem(12px);

    &:hover {
      text-decoration: none;
    }
  }

  &__title_status {
    padding: rem(5px);
    padding-right: rem(5px) !important;
    color: $main-color;
    border-radius: rem(5px);
    margin: rem(-5px) 0 0 0;
    min-width: rem(105px);
  }

  &__accordion_summary {
    min-height: 0 !important;
  }

  &__metrica_item {
    position: relative;
    cursor: pointer;
    max-width: rem(390px);
    min-width: rem(100px);
    width: 90%;
    margin: 0 auto;
    padding: rem(17px);
    border-radius: rem(8px);
    transition: background-color 0.2s linear;
    height: rem(132px);

    &--scanned {
      background-color: $inboundMetricaItemScannedInactive;

      &:hover {
        background-color: $inboundMetricaItemScanned;
      }
    }

    &--ontheway {
      background-color: $inboundMetricaItemOnTheWayInactive;

      &:hover {
        background-color: $inboundMetricaItemOnTheWay;
      }
    }

    &--flagged {
      background-color: $inboundMetricaItemFlaggedInactive;

      &:hover {
        background-color: $inboundMetricaItemFlagged;
      }
    }

    &--handled {
      background-color: $inboundMetricaItemHandledInactive;

      &:hover {
        background-color: $inboundMetricaItemHandled;
      }
    }
  }

  &__metrica_item_header_title {
    text-transform: uppercase;
    font-size: rem(13px);
    font-weight: 600;
    margin: 0 0 rem(10px) 0;
  }

  &__metrica_item_content,
  &__metrica_item_detail {
    text-align: left;
  }

  &__metrica_item_content_value {
    color: $main-color;
    display: inline-block;
    font-size: rem(32px);
    margin: 0 0 rem(15px) 0;
  }

  &__metrica_additional,
  &__metrica_percents {
    display: inline-block;
    color: $main-color;
    font-size: rem(12px);
  }

  &__metrica_percents {
    background: $main-color;
    color: #666666;
    font-size: rem(10px);
    padding: rem(6px);
    border-radius: rem(2px);
    margin: 0 0 0 rem(12px);
  }

  &__metrica_head_control {
    position: absolute;
    right: rem(11px);
    top: rem(11px);
    font-size: 18px !important;
  }

  &__refundable_price_error {
    display: none;
    color: $inboundRemoveColor;
    &.active {
      display: block;
    }
  }

  &__arrived_indicator {
    position: absolute;
    top: 0;
    right: rem(60px);
    z-index: 1;
  }

  &__product_img {
    display: block;
    margin: 0 auto;
    max-width: rem(200px);
    width: rem(120px);
  }

  &__product_img--big {
    border: 1px solid $F50;
  }

  &__send_to_customer_date {
    color: $F100;
    margin: rem(5px) 0 0 0;
    font-size: rem(12px);
    font-style: italic;
  }

  &__expand_icon {
    display: inline-block;
    border: rem(1px) solid #cacaca;
    border-radius: 50%;
    background-color: #f5f5f5;
  }

  &__dropdown_zone {
    margin-bottom: rem(10px);
  }
}