.b-page-content {
  margin: 0 auto;
  padding: rem(5px);
  height: calc(100% - 235px);
  transition: padding 0.3s ease;
  position: relative;
  z-index: 0;

  &--inbound,
  &--returns,
  &--orders,
  &--customers,
  &--security,
  &--item_data_report,
  &--dashboard,
  &--management {
    height: calc(100% - 65px);
    padding: rem(25px);
  }

  /*&--orders.b-page-content--folded_open,
  &--returns.b-page-content--folded_open,
  &--customers.b-page-content--folded_open,
  &--inbound.b-page-content--folded_open,
  &--security.b-page-content--folded_open,
  &--item_data_report.b-page-content--folded_open {
    padding-left: rem(245px);
  }*/

  /*&--folded_open {
    padding-left: rem(223px);
  }*/

  &__wrapper {
    height: 100%;
  }

  &__body {
    margin: 0 0 rem(200px) 0;
    padding: rem(15px) 0 0 0;
    height: 100%;
  }
}