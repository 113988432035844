.b-inbound-page {

  font-family: "Open Sans";

  &__accordion {
    border: 0;
    background-color: transparent !important;
    box-shadow: none !important;

    &:before {
      display: none;
    }
  }

  &__accordion_main {
    display: block !important;
    width: 100%;
    padding: 0 rem(16px) rem(16px) !important;
  }

  &__main_search {
    padding: rem(15px);
    width: 70%;
    margin: 0 auto;
  }

  &__additional_header {
    padding: rem(11px) 0 0 0;
    margin-bottom: 0;
    font-weight: 500;
    font-size: rem(112px);
    text-transform: uppercase;
  }

  &__description {
    font-size: rem(19px);
  }

  &__body {
    padding: 0 rem(35px);
    position: relative;
  }

  &__search_wrapper {
    position: relative;
    width: 50%;
    max-width: rem(764px);
    margin: rem(10px) auto rem(25px) auto;
  }

  &__list {
    display: block;
    padding: 0;
    margin: 0;
  }

  &__order_item {
    list-style-type: none;
  }

  &__bold_item {
    font-weight: 600;
  }

  &__dashboard_img {
    display: block;
    width: 100%;
  }

  &__confirm_control_body {
    border-radius: rem(5px);
    position: relative;
  }

  &__confirm_control_btn {
    box-shadow: none !important;
    font-size: rem(17px) !important;
    top: 0;
    bottom: 0;
    padding: 0;
    margin: 0 !important;
    width: 100% ;
    min-width: 100% !important;
    left: 0;

  }

  &__confirm_control_img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }

  &__customer_info {
    margin: 0 0 rem(5px) 0;
    position: relative;
  }

  &__info_list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    flex-wrap: wrap;
    text-align: left;
  }

  &__info_item {
    text-align: left;
    margin: 0 0 rem(20px) 0;
    min-width: 20%;
  }

  &__info_item_title {
    margin: 0 0 rem(5px) 0;
    font-weight: 600;
  }

  &__info_item_title,
  &__info_item_value {
    font-size: rem(12px);
  }

  &__info_item_value {
    max-width: rem(160px);
  }

  &__hided {
    display: none;
  }

  &__visible {
    display: block;
  }

  &__barcode {
    display: block;
    width: 99%;
    margin: 0 auto;
  }

  &__no_order_found_control {
    font-family: "Open Sans";
    font-size: rem(25px);
  }

  &__no_order_found_control_link {
    font-style: italic;
    color: inherit;
  }

  &__loader_wrapper {
    background: rgba(0, 0, 0, 0.25);
  }

  &__metrica {
    margin: rem(60px) 0 0 0;
  }

  &__pagination_wrapper {
    border: rem(1px) solid $F50;
    background: $main-color;
    border-radius: rem(5px);
    padding: 0 rem(10px) rem(10px) rem(10px);
  }

  &__order_id_relation {
    color: $main-first-color;
  }
}