.b-pagination {
  padding: rem(10px) 0 0 0;
  font-size: rem(14px);



  &__item--pusher {
    flex-grow: 1;
  }

  &__item {
    padding: rem(2px);
  }

  &__item--prev {
    text-align: left;
  }

  &__item--next {
    text-align: right;
  }

  &__btn {
    width: 100%;
    max-width: rem(56px);
  }

  &__item--addinfo {
    text-align: center;
    font-weight: 600;
  }

  &__padding_top {
    padding: rem(8px) 0;
  }

  &__dropdown_control {
    text-align: center;
    > div {
      > div:first-child {
        padding-left: rem(18px);
      }
    }
  }
}