.b-loader {

  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $main-desable-fuse-color;
    z-index: 999;
  }

  &__item {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: rem(-12px);
    margin-top: rem(-12px);
  }

}