.b-login {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-width: $min-width;

  background-size: cover;

  &__body {
    background: $main-color;
    border-radius: rem(10px);
    padding: rem(32px) rem(24px) rem(32px) rem(24px);
    position: relative;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -60%);
    max-width: rem(400px);
  }

  &__form {
    display: block;
    margin: rem(15px) auto 0 auto;
    width: 100%;
  }

  &__logo {
    width: 55%;
  }

  &__img {

  }

  &__item {
    margin: 0 0 rem(5px) 0;

    label {
      background: $main-color
    }
  }

  &__input {
    display: block;
    background: $login-input-bg;
    border: 0;
    border-bottom: rem(1px) solid $main-second-color;
    outline: 0;
    padding: rem(4px) rem(5px);
    width: 100%;
  }

  &__input:focus {
    border-bottom: rem(1px) solid $main-first-color;
  }

  &__label {
    font-size: rem(17px);
  }

  &__p {
    margin: 0 0 rem(20px) 0;
  }

  &__p--reset_password_link {
    margin: 0;
  }

  &__two_factor_auth_icon {
    width: rem(60px);
    height: rem(60px);
    background-size: contain;
    margin-right: rem(15px);
  }

  &__two_factor_auth_icon + div > label {
    white-space: nowrap;
  }


  &__btn {

  }

}

.b-login,
.b-login__two_factor_auth_icon {
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
}

.b-login__item label[data-shrink=false] {
  margin-top: rem(3px);
  font-size: rem(11px);
}