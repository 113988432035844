.b-page {

  &__statuses_table_item {
    border-radius: 2px;
    padding: 2px 4px;
  }

  &__statuses_table_item {
    margin: 0 rem(2px);
  }

}

.b-page-table {

  &__order_list {
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
  }

  &__order_list_item {
    list-style-type: none;
    padding: 0 rem(5px);
  }
}