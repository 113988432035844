.css-bgvzuu-indicatorSeparator {
  width: 0 !important;
}

.ReactTable {
  font-family: $main-font-family;
  font-size: rem(13px);
  text-align: left;

  .rt-thead {
    .rt-resizable-header-content {
      font-weight: 600;
    }
  }

  .rt-thead.-header {
    box-shadow: none !important;
  }

  .rt-tbody {
    .rt-td {
      text-align: center;
    }
  }

  .rt-noData {
    z-index: 0;
  }
}

.management-table {
  .ReactTable {
    .rt-tbody {
      .rt-tr {
        height: rem(28px);
      }
    }
  }
}

.b-grid {
  &__container {
    flex-grow: 1;
  }
}

.b-custom-tooltip-icon {
  transform: rotate(180deg);
}

div[role='tooltip'] {
  * {
    font-size: rem(12px);
  }
}


/* Range Date Picker */

.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside),
.InputFromTo-to .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside){
  background-color: rgba(0,158,134,0.1) !important;
  color: $main-first-color;
}
.InputFromTo .DayPicker-Day,
.InputFromTo-to .DayPicker-Day {
  border-radius: 0 !important;
}
.InputFromTo .DayPicker-Day--start,
.InputFromTo-to .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.InputFromTo .DayPicker-Day--today,
.InputFromTo-to .DayPicker-Day--today {
  color: $main-first-color !important;
}
.InputFromTo .DayPicker-Day--end,
.InputFromTo-to .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
.InputFromTo .DayPickerInput-Overlay,
.InputFromTo-to .DayPickerInput-Overlay {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  width: rem(550px);
  z-index: 2;
}

/*  General */

/*#fa6532 rejectState
#009e86 arrivedState
#aaaaaa pendingState
#da2127 removedState*/

.b-state-color {
  color: $main-color !important;
}

.b-reject-state-bg-color {
  background-color: $inboundRejectColor !important;
}

.b-arrived-state-bg-color {
  background-color: $inboundArrivedColor !important;
}

.b-pending-state-bg-color {
  background-color: #aaaaaa !important;
}

.b-removed-state-bg-color {
  background-color: $inboundRemoveColor !important;
}

.b-btn-received {
  background-color: rgba(0,158,134, 0.9) !important;
  &:hover {
    background-color: rgb(0,158,134) !important;
  }
}

.b-btn-rejected {
  background-color: rgba(250,101,50, 0.9) !important;
  &:hover {
    background-color: rgb(250,101,50) !important;
  }
}

.b-btn-received-inactive {
  background-color: rgba(0,158,134, 0.5) !important;
  &:hover {
    background-color: rgb(0,158,134) !important;
  }
}

.b-btn-rejected-inactive {
  background-color: rgba(250,101,50, 0.5) !important;
  &:hover {
    background-color: rgb(250,101,50) !important;
  }
}

.b-toolbar {
  padding: 0 rem(24px);
  display: flex;
  position: relative;
  align-items: center;
  min-height: rem(64px);
}