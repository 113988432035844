.b-returns-page {

  &__mismatch_warning {
    position: relative;
  }

  &__filters {
    div[class$="-singleValue"] {
      text-transform: capitalize;
    }
  }

  &__controls {
    padding: 0;
    margin: 0 0 rem(15px) 0;
  }

  &__clear_search_ctrl {
    right: rem(3px);
  }

  &__in_table_tooltip {
    position: absolute;
    top: 50%;
    left: rem(-25px);
    margin-top: rem(-11px);
  }

  &__mismatch_warning_icon {
    color: $inboundRemoveColor;
  }
}