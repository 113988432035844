.b-app {
  text-align: center;
  background: $F800;
  height: inherit;

  &__header {
    position: relative;
    z-index: 1;
  }

  &__body,
  &__page {
    height: 100%;
  }

  &__page_body {
    padding: rem(15px) 0 0 0;
  }

  &__body_item {
    position: relative;
    width: 98%;
    margin: 0 auto;
    border-radius: rem(5px);
    padding: 5px;
  }

  &__return_item_loading {
    min-height: rem(200px);
  }

  &__content {
    height: inherit;
    position: relative;
    width: 100%;
    color: $main-text-color;
    display: flex;
    overflow: hidden;
    flex-direction: row;
  }

  &__page_controls {
    padding: rem(5px) rem(5px) 0 rem(5px);
    position: relative;
    z-index: 501;
  }

  &__layout {
    background: $main-fuse-gradient;
    flex: 1 1;
  }

  &__page_content_title {
    margin: 0;
  }

  &__page_content_title--center_align {
    text-align: center;
  }

  &__table_wrapper {
    background: $main-color;
    padding: rem(10px);
    border-radius: rem(5px);
    border: 1px solid $F50;
  }
}
