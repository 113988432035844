.b-server-error-notifier {
  > div {
    > div {
      overflow: visible;
      min-width: rem(600px);
    }
  }

  &--error_dialog {
    > div {
      > div {
        overflow: visible;
        min-width: rem(700px);
      }
    }
  }

  &__list {
    display: block;
    width: 100%;
    padding: 0 0 0 rem(12px);
    color: $black;
  }

  &__item {
    display: block;
    width: 100%;
  }

  &__message {
    display: flex;
    align-items: center;
  }

  &__text {
    padding: rem(25px) 0 0 0;
    text-align: center;
  }

  &__title,
  &__text,
  &__text_p {
    display: block;
  }

  &__title {
    display: block;
    text-align: left;
  }

  &__text_p {
    margin: 0 0 rem(10px) 0;
  }

  &--active {

  }

  &__email_link {
    color: $main-first-color;
  }

  &__close_ctrl {
    position: absolute;
    cursor: pointer;
    right: rem(-12px);
    top: rem(-12px);
    background: $light;
    border-radius: 50%;
  }

  &__actions {
    justify-content: center !important;
    padding: rem(8px) rem(8px) rem(25px) rem(8px) !important;
  }
}

div.b-server-error-notifier__title_wrapper {
  text-align: center;
  color: $main-text-color;
  font-weight: 600;
  font-size: rem(24px);
  position: relative;
  letter-spacing: rem(2px);
  text-transform: uppercase;
  padding: rem(16px) rem(24px) 0 rem(24px);

  &:after {
    content: '';
    position: absolute;
    height: rem(1px);
    background: $F100;
    bottom: -15px;
    left: 40%;
    right: 40%;
  }
}

button.b-server-error-notifier__close_ctrl {
  flex: 0 0 auto;
  padding: rem(12px);
  overflow: visible;
  text-align: center;
  position: absolute;
  top: rem(8px);
  right: rem(8px);
}