.b-header {
  flex-grow: 1;
  color: $main-color;
  background: $main-fuse-gradient;

  &__customer_logo {
    width: rem(100px);
  }

  &__title {
    flex-grow: 1;
    padding: 0 0 0 rem(172px);
  }

  &__profile_menu_icon {

  }

  &__controls {
    z-index: 1;
  }

  &__settings_control {
    color: $main-color;
  }

  &__menu_btn {
    margin: 0 rem(20px) 0 rem(-12px);
  }

  &__username {
    display: flex;
    padding: rem(1px) 0 0 0;
  }

  &__page_logo {
    display: block;
    height: 40px;
    margin: 0 auto;
    width: 100%;
  }

  &__page_title {
    color: $main-first-color;
    cursor: pointer;
    display: inline-block;
    margin: 0 auto;
  }

  &__page_title_icon {
    padding-right: 0;
    margin: 0 rem(10px) 0 0;
    position: relative;
    top: rem(4px);
  }

  &__page_title_text {
    text-transform: uppercase;
    font-weight: 600;
    font-size: rem(22px);
    letter-spacing: rem(1px);
  }

  &__lang_switcher_letter {
    text-transform: uppercase;
    font-size: rem(14px);
    font-weight: 600;
  }

  &__controls_item {
    display: inline-block;
    position: relative;
    padding: 0 rem(5px) 0 rem(7px);
    &:before {
      background: $F100;
      position: absolute;
      content: '';
      width: rem(1px);
      top: rem(-14px);
      bottom: rem(-13px);
      left: 0;
      z-index: -1;
    }
  }

  &__settings_control {
    padding-right: 0 !important;
  }
}