.b-security-page {

  font-family: "Open Sans";

  &__additional_header {
    padding: 11px 0 0 0;
    margin-bottom: 0;
    font-weight: 500;
    font-size: rem(112px);
    text-transform: uppercase;
  }

  &__description {
    font-size: rem(16px);
    margin: rem(10px) auto rem(25px) auto;

    a {
      color: $main-first-color
    }
  }

  &__barCode_btn {
    margin-bottom: rem(10px);
  }

  &__barCode {

  }
}