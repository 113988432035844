.b-page-filter-items {
  display: flex;
  text-align: left;
  font-size: rem(14px);

  &--amount-1 {
    .b-page-filter-items__select {
      min-width: rem(182px);
      max-width: rem(182px);
    }
  }

  &__item_wrapper {
    position: relative;

    &.error input {
      color: $inboundRemoveColor;
    }
  }

  &__label {
    font-weight: 600;
    position: relative;
    color: rgba(0, 0, 0, 0.54);
    font-size: rem(12px);
    margin: rem(1px) 0 rem(2px) rem(2px);
  }

  &__date_range_wrapper {
    width: 90%;
  }

  &__date_range_from,
  &__date_range_to {
    display: inline-block;
    width: 50%;
    padding: 0 rem(2px);
    box-sizing: border-box;

    > .DayPickerInput {
      > input {
        display: block;
        width: 100%;
        border: rem(1px) solid $F80;
        border-radius: rem(5px);
        padding: 0 rem(4px);
        min-height: rem(32px);
        outline: none;
      }
    }
  }

  &__date_range_from--active,
  &__date_range_to--active {
    > .DayPickerInput {
      > input {
        border: rem(1px) solid $F200;
      }
    }
  }

  &__error {
    position: absolute;
    bottom: rem(-15px);
    left: 0;
    right: 0;
    background: $inboundRemoveColor;
    color: $main-color;
    border: rem(1px) solid $inboundRemoveColor;
    padding: rem(4px) rem(3px);
    border-radius: rem(4px);
  }

  &__form-control{
    min-width: 87%;
  }

  &__select,
  &__select--multiple,
  &__item {
    > label {
      font-weight: 600;
      background-color: transparent !important;
      white-space: nowrap;
      overflow: hidden;
      height: auto;
      width: auto;
      display: inline-block;
    }
  }

  &__select,
  &__select--multiple {
    > label {
      background: transparent !important;
    }
  }

  &__select--multiple {
    > label {
      white-space: nowrap;
      font-weight: 600;
    }
  }

  &__item {
    border: 0 !important;

    input {
      font-weight: 600;
    }
  }

  &__select_child_wrapper {
    position: relative;
  }

  &__tooltip {
    position: absolute;
    top: rem(22px);
    right: rem(-22px);
    z-index: 1;
  }

  &__select {
    width: 90% !important;
    margin: 0 auto;
    > div[class$="-container"] {
      background: $main-color;
      border: rem(1px) solid $F80;
      border-radius: rem(5px);

      > div[class$="-control"]  {
        background: transparent;
        border: 0;
        padding: 0 rem(4px);
        min-height: rem(30px);
      }

    }

  }

  &__select--multiple {
    width: 90% !important;
    margin: 0 auto;
    > div[class$="-container"] {
      background: $main-color;
      border: rem(1px) solid $F80;
      border-radius: rem(5px);

      > div[class$="-control"]  {
        background: transparent;
        border: 0;
        padding: 0 rem(4px);
        min-height: rem(30px);

        > div {
          > div {
            background: transparent;
          }
        }
      }

    }

  }

  &__item_combine {
    align-items: flex-end;
  }

  &__select--disabled {
    > div[class$="-container"] {

      > div[class$="-control"]  {
        div[class$="-placeholder"],
        div[class$="-singleValue"] {
          color: rgba(0, 0, 0, 0.38);;
        }
      }

    }
  }

  &__items--active {
    .b-page-filter-items__select,
    .b-page-filter-items__select--multiple {

      > div[class$="-container"] {
        border-color: $F200;
      }

    }

    .b-page-filter-items__select_child_wrapper {
      label {
        color: $main-first-color !important;
      }
    }
  }

  &__date_range_label--active {
    color: $main-first-color;
    white-space: nowrap;
    overflow: hidden;
  }

  &__select_clear {
    cursor: pointer;
    position: absolute;
    color: $black;
    top: 50%;
    right: rem(19px);
    margin-top: rem(14px);
    opacity: 0.6;
    &:hover {
      opacity: 1;
    }
  }

  &__items {
    position: relative;
  }

}

.b-returns-item__head,
.b-inbound-item__head {
  .b-page-filter-items__item--number {
    max-width: rem(150px);
  }
}