.b-dashboard-metrics {

  &__wrapper {
    position: relative;
    max-width: rem(390px);
    min-width: rem(100px);
    width: 90%;
    margin: 0 auto;
  }

  &__metrics {
    margin: rem(60px) 0 0 0;
  }

  &__metrics_item {
    position: relative;
    cursor: pointer;
    margin: 0 auto;
    padding: rem(17px);
    border-radius: rem(8px);
    transition: background-color 0.2s linear;
    height: rem(132px);
    z-index: 1;

    &--mismatch,
    &--scanned {
      background-color: $inboundMetricaItemScannedInactive;

      &:hover {
        background-color: $inboundMetricaItemScanned;
      }
    }

    &--preapproval,
    &--ontheway {
      background-color: $inboundMetricaItemOnTheWayInactive;

      &:hover {
        background-color: $inboundMetricaItemOnTheWay;
      }
    }

    &--flagged {
      background-color: $inboundMetricaItemFlaggedInactive;

      &:hover {
        background-color: $inboundMetricaItemFlagged;
      }
    }

    &--handled {
      background-color: $inboundMetricaItemHandledInactive;

      &:hover {
        background-color: $inboundMetricaItemHandled;
      }
    }
  }

  &__metrics_item_header_title {
    text-transform: uppercase;
    font-size: rem(13px);
    font-weight: 600;
    margin: 0 0 rem(10px) 0;
  }

  &__metrics_item_content,
  &__metrics_item_detail {
    text-align: left;
  }

  &__metrics_item_content_value {
    color: $main-color;
    display: inline-block;
    font-size: rem(32px);
    margin: 0 0 rem(15px) 0;
  }

  &__metrics_additional,
  &__metrics_percents {
    display: inline-block;
    color: $main-color;
    font-size: rem(12px);
  }

  &__metrics_percents {
    background: $main-color;
    color: #666666;
    font-size: rem(10px);
    padding: rem(6px);
    border-radius: rem(2px);
    margin: 0 0 0 rem(12px);
  }

  &__metrics_head_control {
    position: absolute;
    right: rem(11px);
    top: rem(11px);
    font-size: 18px !important;
    z-index: 2;
  }
  &__grid-row{
    flex-grow: 1;
  }
}