.b-returns-table {
  &__clip_to_buffer_wrapper {
    position: relative;
    display: inline-block;
    width: auto;
  }

  &__clip_to_buffer_ctrl {
    position: absolute;
    font-size: rem(14px) !important;
    background-color: transparent;
    background-position: 0 0;
    background-size: contain;
    background-repeat: no-repeat;
    border: 0;
    height: rem(14px);
    width: rem(14px);
    right: rem(-20px);
    cursor: pointer;
    top: 50%;
    margin-top: rem(-7px);
  }

  &__clip_to_buffer_tooltip_wrapper {
    position: relative;
  }

  &__clip_to_buffer_tooltip {
    position: absolute;
    color: $white;
    font-weight: 500;
    font-size: rem(12px);
    background: $A200;
    padding: rem(2px) rem(3px);
    border-radius: rem(4px);
    top: 0;
    left: rem(-44px);
    line-height: rem(14px);
    display: none;
    z-index: 10;

    &.active {
      display: block;
    }
  }

  &__filter_item:first-child {
    width: 84%
  }

  &__filter_item:last-child {
    width: 16%
  }

}