.b-reset-password {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-width: $min-width;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &__header {
    position: relative;
  }

  &__tooltip {
    cursor: pointer;
    position: absolute;
    right: 0
  }

  &__logo {
    width: 55%;
    max-width: rem(240px);
  }

  &__body {
    background: $main-color;
    border-radius: rem(10px);
    padding: rem(32px) rem(24px) rem(52px) rem(24px);
    position: relative;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -60%);
    max-width: rem(400px);

    &--with_note {
      min-width: 60%;
    }
  }

  &__form {
    display: block;
    margin: rem(15px) auto 0 auto;
    width: 100%;
  }

  &__img {

  }

  &__item {
    margin: 0 0 rem(5px) 0;
  }

  &__note {
    padding: rem(20px) 0;
  }

  &__note_header {

  }

  &__note_body {
    text-align: center;
    padding: rem(10px) 0;

    > p {
      line-height: rem(44px);
    }
  }

  &__note_p {
    margin: 0 0 rem(5px) 0;
  }

  &__note_controles {
    position: absolute;
    left: 0;
    right: 0;
    bottom: rem(33px);
  }

  &__input {
    display: block;
    background: $login-input-bg;
    border: 0;
    border-bottom: 1px solid $main-second-color;
    outline: 0;
    padding: rem(4px) rem(5px);
    width: 100%;
  }

  &__input:focus {
    border-bottom: rem(1px) solid $main-first-color;
  }

  &__label {
    font-size: rem(17px);
  }

  &__p {
    margin: 0 0 rem(20px) 0;
  }

  &__p label {
    background: $main-color;
  }

  &__btn {

  }

}