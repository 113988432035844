.b-confirm-before-update-dialog {
  > div {
    > div {
      overflow: visible;
    }
  }


  &__title,
  &__description,
  &__content,
  &__list,
  &__list_item {
    display: block;
    text-align: center;
  }

  &__title,
  &__description {
    text-align: center;
    color: $main-text-color;
  }

  &__title {
    font-weight: 600;
    font-size: rem(24px);
    position: relative;
    letter-spacing: rem(2px);

    &:after {
      content: '';
      position: absolute;
      height: rem(1px);
      background: $F100;
      bottom: rem(-15px);
      left: 40%;
      right: 40%;
    }
  }

  &__description {
    margin: 0 0 rem(15px) 0;
  }

  &__list {
    padding: 0;
    margin: 0;
  }

  &__list_item {
    list-style-type: none;
    margin: 0 0 rem(10px) 0;
  }

  &__reconversion_item {
    font-style: italic;
    color: $main-text-color;
  }

  &__close_ctrl {
    position: absolute;
    cursor: pointer;
    right: rem(-12px);
    top: rem(-12px);
    background: $light;
    border-radius: 50%;
  }

  &__actions {
    padding-bottom: rem(15px) !important;

    > button {
      width: 45%;
      &.b-confirm-before-update-dialog__dialog-btn{
        width: 30%;
        display: block;
        margin: 0 auto
      }
    }
  }
}