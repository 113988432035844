.b-btn {

  &__wrapper {
    position: relative;
  }

  &__loading {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: rem(-12px);
    margin-top: rem(-12px);
  }
}