.b-mismatch-tooltip {
  &__mismatch_warning_icon {
    fill: $inboundRemoveColor !important;
    position: relative;
    top: rem(7px);
    left: rem(-2px);
  }
  &__mismatch_warning_icon--inbound {
    top: 0;
  }
}