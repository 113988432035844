.b-drop-zone {
  position: relative;
  width: 98%;
  margin: rem(7px) 0 0 0;

  &__loader {
    z-index: 10;
  }

  &__zone {
    min-height: rem(75px) !important;
    outline: none;
    border: rem(1px) solid !important;
    border-color: $F80 !important;
    background-color: transparent;
    border-radius: 0 !important;
    overflow: visible !important;
    height: 100%;

    .MuiDropzoneArea-textContainer {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      height: 100%;

      > svg {
        color: #5f5f5f;
        width: rem(35px);
        height: rem(35px);
      }
    }
  }

  &__intro {
    font-size: rem(12px) !important;
    letter-spacing: 0.02rem !important;
    font-style: italic;
    color: $black;
    margin-top: 0 !important;
    margin-bottom: rem(30px) !important;
  }

  &__zone--loaded {
    border-color: $F200 !important;
    .b-drop-zone__intro {
      font-size: rem(10px) !important;
      margin-bottom: rem(26px) !important;
    }
  }

  &__container {
    width: 100% !important;
    margin: auto !important;
    align-content: space-around;
    justify-content: center;
  }

  &__item {
    padding: rem(2px) !important;

    > img {
      display: block;
      margin: 0 auto;
      height: rem(70px);
    }
  }

  &__carousel_item {
    height: rem(102px);
  }

  &__carousel_item--carousel {
    border: rem(1px) solid $black;
    margin: 0 rem(3px) 0 0;
  }

  &__carousel_item--single {
    border: 0;
    flex-basis: 50% !important;
  }

  &__carousel_item:first-child {
    flex-basis: 75%;
  }

  &__carousel_item:last-child {
    flex-basis: 25%;
  }

  &__carousel_item--full {
    flex-basis: 100% !important;
  }

  &__carousel_img_wrapper {
    position: relative;
  }

  &__carousel_img_wrapper--single {
    border: rem(1px) solid $black;
  }

  &__carousel_img_remove {
    cursor: pointer;
    position: absolute;
    color: $black;
    opacity: 1;
    background: $F100;
    border-radius: 50%;
    top: rem(2px);
    right: rem(5px);
  }

  &__carousel_img {
    width: initial !important;
    max-width: 100%;
    height: rem(100px);
    display: block;
    margin: 0 auto;
  }

  &__carousel_img--big {
    border: 1px solid $F50;
  }

  &__carousel_ctrl {
    position: absolute;
    z-index: 2;
    top: calc(50% - 15px);
    width: rem(30px);
    height: rem(30px);
    cursor: pointer;
    stroke: $white;
  }

  &__carousel_ctrl--prev {
    left: rem(5px);
  }

  &__carousel_ctrl--next {
    right: rem(-2px);
  }

  &__images_label {
    text-align: left;
    padding: rem(10px) 0 0 0;
    font-weight: 600;
  }

}

.carousel .slide {
  background: transparent !important;
  border-left: rem(1px) solid $F200;
  border-right: rem(1px) solid $F200;
  margin: 0 rem(1px) !important;
}