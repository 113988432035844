.b-analytics-page {

  &__paper {
    padding: rem(5px) 0 0 0;
  }

  &__generate_controls {
    padding: rem(25px) rem(17px) 0 0;
  }

  &__generate_controls_item {
    width: 15%;
    margin: 0 rem(10px);
  }

  &__generate_controls_item:first-child {
    width: 70%;
  }

  &__generate_controls_btn {
    margin: 0 rem(5px);
  }

  &__filters {
    justify-content: left;
  }

  &__additional_header {
    padding: rem(11px) 0 0 0;
    margin-bottom: 0;
    font-weight: 500;
    font-size: rem(112px);
    text-transform: uppercase;
  }

  &__grid_container {
    flex-grow: 1;
  }

  &__btn_wrapper {
    display: inline-block;
    margin: 0 rem(2px);
    padding: rem(15px) 0 0 0;
    width: 90%;
    max-width: rem(240px);
    min-width: rem(150px);
  }

  &__metric_wrapper {
    margin-top: rem(12px);
    text-align: left;
  }

  &__metric_body,
  &__metric_header {
    background: $main-color;
    border-radius: rem(4px);
    border: rem(1px) solid $F80;
  }

  &__metric_body {
    padding: rem(35px) rem(55px);
  }

  &__tabs {

  }

  &__tabs_list {
    list-style-type: none;
    margin: 0;
    padding: 0 0 0 rem(10px);
  }

  &__tabs_item {
    cursor: pointer;
    display: inline-block;
    padding: rem(20px) rem(27px);
    font-size: rem(14px);
    color: $main-first-color;
    font-weight: 600;
    position: relative;

    &.active:after {
      background: $main-first-color;
      bottom: 0;
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: rem(2px);
    }
  }

  &__cards_wrapper {
    margin-bottom: rem(42px);
    justify-content: space-around;
  }

  &__chart_wrapper {
    padding: rem(10px);
    border: rem(1px) solid $F80;
    border-radius: rem(10px);
    margin: 0 0 rem(42px) 0;
  }

  &__chart_wrapper_header {
    border-bottom: rem(1px) solid $F80;
    padding: rem(25px) 0 rem(28px) 0;
    margin: 0 0 rem(22px) 0;
  }

  &__chart_wrapper_header--simple {
    border-bottom: 0;
    margin: 0;
  }

  &__chart_wrapper_title {
    width: rem(200px);
    font-weight: 700;
    padding: 0 0 0 rem(17px);
    font-size: rem(18px);
    letter-spacing: 0.05rem;
    white-space: nowrap;
  }

  &__chart_wrapper_controls {
    width: 100%;
    text-align: right;
  }

  &__chart_wrapper_list {
    position: relative;
    top: rem(4px);
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: inline-block;
  }

  &__chart_wrapper_list_item {
    cursor: pointer;
    display: inline-block;
    margin: 0 rem(31px) 0 0;
    font-size: rem(14px);
    font-weight: 500;
    letter-spacing: 0.05rem;
    position: relative;

    &:hover {
      color: $main-first-color;
    }
  }

  &__chart_wrapper_list_item:first-child {
    margin: 0 rem(35px) 0 0;
  }

  &__chart_wrapper_list_item.active {
    color: $main-first-color;

    &:after {
      content: '';
      background: $main-first-color;
      position: absolute;
      left: rem(-2px);
      right: rem(-2px);
      bottom: rem(-2px);
      height: 1px;
    }
  }

  &__bar_chart_argument_label {
    font-size: rem(11px);
  }

}

.b-analytics-card {
  border-radius: rem(10px);
  border: rem(1px) solid $F80;
  border-top: 0;
  margin: 0 rem(7px);
  width: 25%;
  min-width: rem(100px);
  text-align: center;
  height: rem(222px);
  overflow: hidden;

  &__top {
    border-top: rem(1px) solid $F80;
    border-radius: rem(10px) rem(10px) 0 0;
    background: $dashBoardCardGreenColor;
    height: rem(14px);
  }

  &__top--green {
    background: $dashBoardCardGreenColor;
  }

  &__value--green {
    color: $dashBoardCardGreenColor;
  }

  &__top--orange {
    background: $dashBoardCardOrangeColor;
  }

  &__value--orange {
    color: $dashBoardCardOrangeColor;
  }

  &__top--black {
    background: $dashBoardCardBlackColor;
  }

  &__value--black {
    color: $dashBoardCardBlackColor;
  }

  &__body {
    border-bottom: rem(1px) solid $F80;
    padding: 0 0 rem(15px) 0;
    min-height: rem(135px);
  }

  &__name {
    font-weight: 600;
    padding: rem(13px) 0;
    max-height: rem(44px);
    overflow: hidden;
    white-space: nowrap;
  }

  &__value {
    //font-weight: 600;
    font-size: 350%;
  }

  &__value--values {
    font-size: 250%;
    padding: rem(22px) 0 rem(17px) 0;
    white-space: nowrap;
  }

  &__footer {
    position: relative;
  }

  &__titles {
    position: relative;
    width: 55%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 1%);
  }

  &__titles--values {
    transform: translate(-50%, 15%);
  }

  &__titles_list {
    margin: 0;
    padding: rem(9px) 0 0 0;
    list-style-type: none;
  }

  &__titles_item {
    font-size: rem(12px);
    padding: rem(4px) 0;
    position: relative;

    &:after {
      background: #000000;
      content: '';
      position: absolute;
      bottom: rem(-1px);
      left: 50%;
      margin-left: rem(-50px);
      width: rem(100px);
      height: rem(1px);
    }
  }

  &__titles_item:last-child:after {
    display: none;
  }
}

//CHARTS
.b-analytics-overtime-chart {
  &__legend_list {
    padding: 0;
    margin: rem(10px) 0 0 0;
  }

  &__legend_list_item_marker {
    position: absolute;
    width: rem(16px);
    height: rem(16px);
    top: 50%;
    margin-top: rem(-8px);
    left: rem(-25px);
  }

  &__legend_list_item {
    position: relative;
    display: inline-block;
    margin: 0 rem(25px);
    font-size: rem(16px);
    font-weight: 400;
    padding: rem(10px) 0;

  }

  &__argument_axis_label {
    font-size: rem(12px);
    color: red;
  }
}
@media (max-width: 1420px) {
  .b-analytics-card {
    &__value--rates {
      font-size: 250%;
      margin: rem(12px) 0 0 0;
    }
  }
}

@media (max-width: 1030px) {
  .b-analytics-card {
    &__value {
      font-size: 195%;
      margin: rem(12px) 0 0 0;
    }
    &__value--rates {
      margin: rem(18px) 0 0 0;
    }
  }
}

@media (max-width: 880px) {
  .b-analytics-card {
    &__value {
      font-size: 150%;
      margin: rem(12px) 0 0 0;
    }
    &__value--rates {
      margin: rem(18px) 0 0 0;
    }
  }
}