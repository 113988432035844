/* General colors */
/* FUSE colors */
/* General fonts */
/* Return additional controls */
/* General Dimensions */
/* Functions */
/* Mixin */
html {
  width: 100%;
  height: 100%; }

body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  background: #F5F5F5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box; }
  body * {
    box-sizing: inherit; }

body,
#root {
  color: #262933;
  position: relative;
  width: inherit;
  height: inherit; }

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #FFFFFF inset; }

.b-flex {
  display: flex;
  flex-wrap: nowrap; }
  .b-flex--reverse {
    flex-direction: row-reverse; }
  .b-flex--wrap {
    flex-wrap: wrap; }

.uppercase {
  text-transform: uppercase; }

.hidden {
  display: none; }

/* Components */
.b-header {
  flex-grow: 1;
  color: #FFFFFF;
  background: linear-gradient(to right, #2D323E 0%, #3C4252 100%); }
  .b-header__customer_logo {
    width: 6.25rem; }
  .b-header__title {
    flex-grow: 1;
    padding: 0 0 0 10.75rem; }
  .b-header__controls {
    z-index: 1; }
  .b-header__settings_control {
    color: #FFFFFF; }
  .b-header__menu_btn {
    margin: 0 1.25rem 0 -0.75rem; }
  .b-header__username {
    display: flex;
    padding: 0.0625rem 0 0 0; }
  .b-header__page_logo {
    display: block;
    height: 40px;
    margin: 0 auto;
    width: 100%; }
  .b-header__page_title {
    color: #009E86;
    cursor: pointer;
    display: inline-block;
    margin: 0 auto; }
  .b-header__page_title_icon {
    padding-right: 0;
    margin: 0 0.625rem 0 0;
    position: relative;
    top: 0.25rem; }
  .b-header__page_title_text {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.375rem;
    letter-spacing: 0.0625rem; }
  .b-header__lang_switcher_letter {
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 600; }
  .b-header__controls_item {
    display: inline-block;
    position: relative;
    padding: 0 0.3125rem 0 0.4375rem; }
    .b-header__controls_item:before {
      background: #C5C6CB;
      position: absolute;
      content: '';
      width: 0.0625rem;
      top: -0.875rem;
      bottom: -0.8125rem;
      left: 0;
      z-index: -1; }
  .b-header__settings_control {
    padding-right: 0 !important; }

.b-server-error-notifier > div > div {
  overflow: visible;
  min-width: 37.5rem; }

.b-server-error-notifier--error_dialog > div > div {
  overflow: visible;
  min-width: 43.75rem; }

.b-server-error-notifier__list {
  display: block;
  width: 100%;
  padding: 0 0 0 0.75rem;
  color: #010101; }

.b-server-error-notifier__item {
  display: block;
  width: 100%; }

.b-server-error-notifier__message {
  display: flex;
  align-items: center; }

.b-server-error-notifier__text {
  padding: 1.5625rem 0 0 0;
  text-align: center; }

.b-server-error-notifier__title, .b-server-error-notifier__text, .b-server-error-notifier__text_p {
  display: block; }

.b-server-error-notifier__title {
  display: block;
  text-align: left; }

.b-server-error-notifier__text_p {
  margin: 0 0 0.625rem 0; }

.b-server-error-notifier__email_link {
  color: #009E86; }

.b-server-error-notifier__close_ctrl {
  position: absolute;
  cursor: pointer;
  right: -0.75rem;
  top: -0.75rem;
  background: #FFFFFF;
  border-radius: 50%; }

.b-server-error-notifier__actions {
  justify-content: center !important;
  padding: 0.5rem 0.5rem 1.5625rem 0.5rem !important; }

div.b-server-error-notifier__title_wrapper {
  text-align: center;
  color: #555555;
  font-weight: 600;
  font-size: 1.5rem;
  position: relative;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
  padding: 1rem 1.5rem 0 1.5rem; }
  div.b-server-error-notifier__title_wrapper:after {
    content: '';
    position: absolute;
    height: 0.0625rem;
    background: #C5C6CB;
    bottom: -15px;
    left: 40%;
    right: 40%; }

button.b-server-error-notifier__close_ctrl {
  flex: 0 0 auto;
  padding: 0.75rem;
  overflow: visible;
  text-align: center;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem; }

.b-server-success-notifier__message {
  display: flex;
  align-items: center; }

.b-server-success-notifier__title {
  text-align: left; }

.b-server-success-notifier__text {
  padding: 0.25rem 0 0 0.625rem; }

.b-server-success-notifier__list {
  text-align: left; }

.b-login {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-width: 300px;
  background-size: cover; }
  .b-login__body {
    background: #FFFFFF;
    border-radius: 0.625rem;
    padding: 2rem 1.5rem 2rem 1.5rem;
    position: relative;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -60%);
    max-width: 25rem; }
  .b-login__form {
    display: block;
    margin: 0.9375rem auto 0 auto;
    width: 100%; }
  .b-login__logo {
    width: 55%; }
  .b-login__item {
    margin: 0 0 0.3125rem 0; }
    .b-login__item label {
      background: #FFFFFF; }
  .b-login__input {
    display: block;
    background: #e8f0fe;
    border: 0;
    border-bottom: 0.0625rem solid #1A3865;
    outline: 0;
    padding: 0.25rem 0.3125rem;
    width: 100%; }
  .b-login__input:focus {
    border-bottom: 0.0625rem solid #009E86; }
  .b-login__label {
    font-size: 1.0625rem; }
  .b-login__p {
    margin: 0 0 1.25rem 0; }
  .b-login__p--reset_password_link {
    margin: 0; }
  .b-login__two_factor_auth_icon {
    width: 3.75rem;
    height: 3.75rem;
    background-size: contain;
    margin-right: 0.9375rem; }
  .b-login__two_factor_auth_icon + div > label {
    white-space: nowrap; }

.b-login,
.b-login__two_factor_auth_icon {
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat; }

.b-login__item label[data-shrink=false] {
  margin-top: 0.1875rem;
  font-size: 0.6875rem; }

.b-reset-password {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-width: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }
  .b-reset-password__header {
    position: relative; }
  .b-reset-password__tooltip {
    cursor: pointer;
    position: absolute;
    right: 0; }
  .b-reset-password__logo {
    width: 55%;
    max-width: 15rem; }
  .b-reset-password__body {
    background: #FFFFFF;
    border-radius: 0.625rem;
    padding: 2rem 1.5rem 3.25rem 1.5rem;
    position: relative;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -60%);
    max-width: 25rem; }
    .b-reset-password__body--with_note {
      min-width: 60%; }
  .b-reset-password__form {
    display: block;
    margin: 0.9375rem auto 0 auto;
    width: 100%; }
  .b-reset-password__item {
    margin: 0 0 0.3125rem 0; }
  .b-reset-password__note {
    padding: 1.25rem 0; }
  .b-reset-password__note_body {
    text-align: center;
    padding: 0.625rem 0; }
    .b-reset-password__note_body > p {
      line-height: 2.75rem; }
  .b-reset-password__note_p {
    margin: 0 0 0.3125rem 0; }
  .b-reset-password__note_controles {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 2.0625rem; }
  .b-reset-password__input {
    display: block;
    background: #e8f0fe;
    border: 0;
    border-bottom: 1px solid #1A3865;
    outline: 0;
    padding: 0.25rem 0.3125rem;
    width: 100%; }
  .b-reset-password__input:focus {
    border-bottom: 0.0625rem solid #009E86; }
  .b-reset-password__label {
    font-size: 1.0625rem; }
  .b-reset-password__p {
    margin: 0 0 1.25rem 0; }
  .b-reset-password__p label {
    background: #FFFFFF; }

.b-left-menu__folded {
  position: absolute;
  width: 3.875rem;
  min-width: 3.875rem;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
  z-index: 4;
  transition: width 0.2s ease-in, min-width 0.2s ease-in;
  background-color: #303030; }

.b-left-menu__folded--open {
  width: 17.5rem;
  min-width: 17.5rem; }

.b-left-menu__header {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  justify-content: flex-start; }

.b-left-menu__folded--paper {
  background-color: #303030 !important;
  width: 17.5rem; }

.b-right-menu__header {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  justify-content: flex-start; }

.b-page-header {
  display: flex;
  flex-direction: column;
  padding: 0.9375rem 1.5rem;
  transition: padding-left 0.3s ease; }
  .b-page-header--padding_left {
    padding-left: 15rem; }
  .b-page-header__additional {
    display: flex;
    align-content: center;
    text-align: left;
    align-items: inherit; }
  .b-page-header__breadcrumbs {
    margin: 0 0 0.9375rem 0; }
  .b-page-header__controls {
    flex-grow: 1; }

.b-navigation-menu__header {
  padding: 0.9375rem 0.625rem 0 0.4375rem;
  text-align: left; }

.b-navigation-menu__logo {
  width: 2.9375rem;
  max-height: 3.75rem;
  display: block;
  margin: 0 auto; }

.b-navigation-menu__logo--toteme_toteme {
  position: relative;
  background: #FFFFFF;
  top: -0.4375rem; }

.b-navigation-menu__logo--magento_bjornborg, .b-navigation-menu__logo--royal_design_royal_design {
  width: 100%;
  padding: 0.5rem 0.3125rem; }

.b-navigation-menu__logo--centra_asket, .b-navigation-menu__logo--magento_hh {
  width: 100%;
  max-width: 4.375rem;
  padding: 0 0 0.875rem 0; }

.b-navigation-menu__bottom_logo {
  width: 2.9375rem;
  display: inline-block;
  margin: 0 auto 0 auto; }

.b-navigation-menu__bottom_logo_text {
  display: none;
  position: absolute;
  height: 2.5rem; }

.b-navigation-menu__bottom_logo_wrapper {
  position: absolute;
  transition: width 1s linear;
  bottom: 1.25rem; }

.b-navigation-menu__bottom_logo_wrapper {
  width: 100%; }

.b-navigation-menu__bottom_logo_wrapper--open .b-navigation-menu__bottom_logo {
  margin-left: 2.3125rem; }

.b-navigation-menu__bottom_logo_wrapper--open .b-navigation-menu__bottom_logo_text {
  display: inline-block;
  left: 6.375rem; }

.b-navigation-menu__item {
  height: 2.5rem;
  width: calc(100% - 10px) !important;
  border-radius: 0 1.25rem 1.25rem 0 !important;
  padding-right: 0.75rem !important;
  white-space: nowrap; }

.b-navigation-menu__item_icon {
  padding-right: 0;
  margin: 0 0.375rem 0 0.0625rem; }

.b-page-control-items {
  transition: margin-left 0.3s ease;
  text-align: left; }
  .b-page-control-items--folded_open {
    margin-left: 13.625rem; }
  .b-page-control-items__body {
    flex-grow: 1; }
  .b-page-control-items__body_item {
    text-align: center; }
  .b-page-control-items__body_item--search {
    padding: 0.3125rem 0 0 0; }

.b-page-filter-items {
  display: flex;
  text-align: left;
  font-size: 0.875rem; }
  .b-page-filter-items--amount-1 .b-page-filter-items__select {
    min-width: 11.375rem;
    max-width: 11.375rem; }
  .b-page-filter-items__item_wrapper {
    position: relative; }
    .b-page-filter-items__item_wrapper.error input {
      color: #da2127; }
  .b-page-filter-items__label {
    font-weight: 600;
    position: relative;
    color: rgba(0, 0, 0, 0.54);
    font-size: 0.75rem;
    margin: 0.0625rem 0 0.125rem 0.125rem; }
  .b-page-filter-items__date_range_wrapper {
    width: 90%; }
  .b-page-filter-items__date_range_from, .b-page-filter-items__date_range_to {
    display: inline-block;
    width: 50%;
    padding: 0 0.125rem;
    box-sizing: border-box; }
    .b-page-filter-items__date_range_from > .DayPickerInput > input, .b-page-filter-items__date_range_to > .DayPickerInput > input {
      display: block;
      width: 100%;
      border: 0.0625rem solid #ebebeb;
      border-radius: 0.3125rem;
      padding: 0 0.25rem;
      min-height: 2rem;
      outline: none; }
  .b-page-filter-items__date_range_from--active > .DayPickerInput > input, .b-page-filter-items__date_range_to--active > .DayPickerInput > input {
    border: 0.0625rem solid #9EA1A9; }
  .b-page-filter-items__error {
    position: absolute;
    bottom: -0.9375rem;
    left: 0;
    right: 0;
    background: #da2127;
    color: #FFFFFF;
    border: 0.0625rem solid #da2127;
    padding: 0.25rem 0.1875rem;
    border-radius: 0.25rem; }
  .b-page-filter-items__form-control {
    min-width: 87%; }
  .b-page-filter-items__select > label, .b-page-filter-items__select--multiple > label, .b-page-filter-items__item > label {
    font-weight: 600;
    background-color: transparent !important;
    white-space: nowrap;
    overflow: hidden;
    height: auto;
    width: auto;
    display: inline-block; }
  .b-page-filter-items__select > label, .b-page-filter-items__select--multiple > label {
    background: transparent !important; }
  .b-page-filter-items__select--multiple > label {
    white-space: nowrap;
    font-weight: 600; }
  .b-page-filter-items__item {
    border: 0 !important; }
    .b-page-filter-items__item input {
      font-weight: 600; }
  .b-page-filter-items__select_child_wrapper {
    position: relative; }
  .b-page-filter-items__tooltip {
    position: absolute;
    top: 1.375rem;
    right: -1.375rem;
    z-index: 1; }
  .b-page-filter-items__select {
    width: 90% !important;
    margin: 0 auto; }
    .b-page-filter-items__select > div[class$="-container"] {
      background: #FFFFFF;
      border: 0.0625rem solid #ebebeb;
      border-radius: 0.3125rem; }
      .b-page-filter-items__select > div[class$="-container"] > div[class$="-control"] {
        background: transparent;
        border: 0;
        padding: 0 0.25rem;
        min-height: 1.875rem; }
  .b-page-filter-items__select--multiple {
    width: 90% !important;
    margin: 0 auto; }
    .b-page-filter-items__select--multiple > div[class$="-container"] {
      background: #FFFFFF;
      border: 0.0625rem solid #ebebeb;
      border-radius: 0.3125rem; }
      .b-page-filter-items__select--multiple > div[class$="-container"] > div[class$="-control"] {
        background: transparent;
        border: 0;
        padding: 0 0.25rem;
        min-height: 1.875rem; }
        .b-page-filter-items__select--multiple > div[class$="-container"] > div[class$="-control"] > div > div {
          background: transparent; }
  .b-page-filter-items__item_combine {
    align-items: flex-end; }
  .b-page-filter-items__select--disabled > div[class$="-container"] > div[class$="-control"] div[class$="-placeholder"],
  .b-page-filter-items__select--disabled > div[class$="-container"] > div[class$="-control"] div[class$="-singleValue"] {
    color: rgba(0, 0, 0, 0.38); }
  .b-page-filter-items__items--active .b-page-filter-items__select > div[class$="-container"],
  .b-page-filter-items__items--active .b-page-filter-items__select--multiple > div[class$="-container"] {
    border-color: #9EA1A9; }
  .b-page-filter-items__items--active .b-page-filter-items__select_child_wrapper label {
    color: #009E86 !important; }
  .b-page-filter-items__date_range_label--active {
    color: #009E86;
    white-space: nowrap;
    overflow: hidden; }
  .b-page-filter-items__select_clear {
    cursor: pointer;
    position: absolute;
    color: #010101;
    top: 50%;
    right: 1.1875rem;
    margin-top: 0.875rem;
    opacity: 0.6; }
    .b-page-filter-items__select_clear:hover {
      opacity: 1; }
  .b-page-filter-items__items {
    position: relative; }

.b-returns-item__head .b-page-filter-items__item--number,
.b-inbound-item__head .b-page-filter-items__item--number {
  max-width: 9.375rem; }

.b-page-table {
  position: relative;
  min-height: 3rem; }

.b-translation__loader {
  width: 1rem !important;
  height: 1rem !important; }

.b-search {
  position: relative; }
  .b-search input {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem; }
  .b-search__p:first-child {
    width: 100%;
    margin: 0 0.625rem 0 0; }
    .b-search__p:first-child > div {
      margin: 0; }
  .b-search__search_clear {
    position: absolute;
    top: 50%;
    cursor: pointer;
    margin-top: -0.5em; }
  .b-search__search_clear--with_search_control {
    right: 6.5625rem; }
  .b-search__submit {
    padding: 0.5625rem 1rem !important;
    top: 0.0625rem; }

.b-search--returns {
  margin: 0.9375rem 0 0 0; }
  .b-search--returns > form input {
    box-sizing: border-box;
    padding-right: 1.5rem; }

.b-search--orders {
  margin: 0 0 0.625rem 0; }
  .b-search--orders__p:first-child {
    margin: 0 0.625rem 0 0; }

.b-return-description {
  text-align: left;
  padding: 0 0 0.625rem 1rem;
  font-size: 0.6875rem;
  max-width: 80rem; }
  .b-return-description--inbound {
    padding: 0 0 1.1875rem 1.1875rem; }
  .b-return-description__title {
    text-transform: uppercase;
    font-weight: 600;
    position: relative;
    margin: 0 0 0.4375rem 0; }
  .b-return-description__title--actions {
    display: flex;
    align-items: flex-end;
    margin: 0;
    height: 1.125rem; }
  .b-return-description__main_list {
    list-style-type: none;
    padding: 0; }
  .b-return-description__main_list_item {
    padding: 0 0.625rem 1.875rem 0.8125rem;
    width: 20%;
    min-width: 160px; }
  .b-return-description__main_list_item:first-child {
    padding: 0 0.625rem 1.875rem 1.875rem; }
  .b-return-description__main_list_item--reconversion {
    width: 20%; }
  .b-return-description__main_list_item_inner {
    width: 100%; }
  .b-return-description__main_list_item:first-child {
    margin: 0; }
  .b-return-description__icon {
    position: absolute;
    content: '';
    width: 1rem;
    height: 1rem;
    top: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0; }
  .b-return-description__icon--customer {
    left: -1.125rem; }
  .b-return-description__icon--actions {
    background-position: -0.9375rem 0;
    left: -1.3125rem; }
  .b-return-description__icon--shipping {
    background-position: -4.25rem 0;
    width: 1.125rem;
    left: -1.4375rem; }
  .b-return-description__icon--refund {
    background-position: -2rem 0;
    left: -1.3125rem; }
  .b-return-description__icon--reconversion {
    background-position: -3.125rem 0;
    left: -1.3125rem; }
  .b-return-description__list {
    padding: 0.3125rem 0 0 0;
    margin: 0;
    list-style-type: none; }
  .b-return-description__list_item {
    margin: 0 0 0.4375rem 0;
    position: relative; }
    .b-return-description__list_item:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      clear: both; }
  .b-return-description__list_item--parcel {
    margin: 0 0 0.125rem 0;
    text-decoration: underline;
    font-style: italic; }
  .b-return-description__list_item_title, .b-return-description__list_item_description {
    white-space: nowrap; }
  .b-return-description__list_item_title {
    font-weight: 600; }
  .b-return-description__list_item_description {
    padding: 0 0 0 0.1875rem;
    float: right; }
  .b-return-description__list_item_description--sublist {
    float: none;
    font-size: 0.625rem; }
  .b-return-description__list_item_description--shipment_status {
    float: none; }
  .b-return-description__list_item_description--right {
    text-align: right; }
  .b-return-description__list_item_description--logtrade {
    white-space: inherit; }
  .b-return-description__sublist {
    padding: 0;
    list-style-type: none; }
  .b-return-description__sublist_item {
    margin: 0 0 0.3125rem 0; }
  .b-return-description__title_status {
    display: inline-block;
    padding: 0.125rem;
    border-radius: 0.3125rem;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 0.625rem;
    color: #FFFFFF; }
  .b-return-description__sublist_item_article_id {
    margin: 0; }
  .b-return-description__sublist_item_article_id_push {
    visibility: hidden; }
  .b-return-description__external_status {
    position: relative;
    margin-top: 0.3125rem; }
  .b-return-description__flex_breaker {
    flex-basis: 100%;
    height: 0; }
  .b-return-description__tooltip {
    position: absolute;
    display: inline-block;
    margin-left: 1.5625rem;
    height: 100%;
    top: -2px;
    right: -25px; }
  .b-return-description__tooltip_icon {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -0.5625rem; }
  .b-return-description__return_fixed_fee {
    border-top: 0.0625rem solid;
    clear: both; }
  .b-return-description__reconversion_item_attr {
    display: block;
    width: 100%; }
  .b-return-description__reconversion_item_attr--with_push {
    font-size: 0.5625rem;
    padding: 0 0 0 0.1875rem;
    margin: 0.125rem 0;
    font-style: italic;
    height: 0.0625rem; }
  .b-return-description__log_icon {
    position: relative;
    cursor: pointer;
    fill: #3C4252 !important; }

@media (max-width: 1730px) {
  .b-return-description__list_item_title, .b-return-description__list_item_description {
    white-space: normal; }
  .b-return-description__list_item_description {
    float: none; } }

.b-confirm-before-update-dialog > div > div {
  overflow: visible; }

.b-confirm-before-update-dialog__title, .b-confirm-before-update-dialog__description, .b-confirm-before-update-dialog__content, .b-confirm-before-update-dialog__list, .b-confirm-before-update-dialog__list_item {
  display: block;
  text-align: center; }

.b-confirm-before-update-dialog__title, .b-confirm-before-update-dialog__description {
  text-align: center;
  color: #555555; }

.b-confirm-before-update-dialog__title {
  font-weight: 600;
  font-size: 1.5rem;
  position: relative;
  letter-spacing: 0.125rem; }
  .b-confirm-before-update-dialog__title:after {
    content: '';
    position: absolute;
    height: 0.0625rem;
    background: #C5C6CB;
    bottom: -0.9375rem;
    left: 40%;
    right: 40%; }

.b-confirm-before-update-dialog__description {
  margin: 0 0 0.9375rem 0; }

.b-confirm-before-update-dialog__list {
  padding: 0;
  margin: 0; }

.b-confirm-before-update-dialog__list_item {
  list-style-type: none;
  margin: 0 0 0.625rem 0; }

.b-confirm-before-update-dialog__reconversion_item {
  font-style: italic;
  color: #555555; }

.b-confirm-before-update-dialog__close_ctrl {
  position: absolute;
  cursor: pointer;
  right: -0.75rem;
  top: -0.75rem;
  background: #FFFFFF;
  border-radius: 50%; }

.b-confirm-before-update-dialog__actions {
  padding-bottom: 0.9375rem !important; }
  .b-confirm-before-update-dialog__actions > button {
    width: 45%; }
    .b-confirm-before-update-dialog__actions > button.b-confirm-before-update-dialog__dialog-btn {
      width: 30%;
      display: block;
      margin: 0 auto; }

.b-returns-comments-areas {
  margin: 0.9375rem 0 0 0; }
  .b-returns-comments-areas__comments_title {
    text-align: left;
    padding: 0.625rem 0;
    font-weight: 600; }
  .b-returns-comments-areas__product_comments_block {
    margin: 0.9375rem 0 0 0; }
  .b-returns-comments-areas__comments {
    padding: 0 0.9375rem 0 0;
    display: flex;
    width: 100%; }
  .b-returns-comments-areas__comments_customer, .b-returns-comments-areas__comments_internal {
    width: 100%; }
  .b-returns-comments-areas__comments_textarea {
    border: 0.0625rem solid #ECECEE;
    display: block;
    height: 4.6875rem;
    padding: 0.3125rem;
    width: 98%;
    resize: none; }
  .b-returns-comments-areas__comments_textarea.active {
    border-color: #010101; }
  .b-returns-comments-areas__comments_textarea::placeholder {
    color: #C5C6CB;
    font-style: italic; }

.b-dashboard-metrics__wrapper {
  position: relative;
  max-width: 24.375rem;
  min-width: 6.25rem;
  width: 90%;
  margin: 0 auto; }

.b-dashboard-metrics__metrics {
  margin: 3.75rem 0 0 0; }

.b-dashboard-metrics__metrics_item {
  position: relative;
  cursor: pointer;
  margin: 0 auto;
  padding: 1.0625rem;
  border-radius: 0.5rem;
  transition: background-color 0.2s linear;
  height: 8.25rem;
  z-index: 1; }
  .b-dashboard-metrics__metrics_item--mismatch, .b-dashboard-metrics__metrics_item--scanned {
    background-color: rgba(255, 116, 116, 0.85); }
    .b-dashboard-metrics__metrics_item--mismatch:hover, .b-dashboard-metrics__metrics_item--scanned:hover {
      background-color: #ff7474; }
  .b-dashboard-metrics__metrics_item--preapproval, .b-dashboard-metrics__metrics_item--ontheway {
    background-color: rgba(211, 186, 44, 0.85); }
    .b-dashboard-metrics__metrics_item--preapproval:hover, .b-dashboard-metrics__metrics_item--ontheway:hover {
      background-color: #d3ba2c; }
  .b-dashboard-metrics__metrics_item--flagged {
    background-color: rgba(16, 16, 16, 0.85); }
    .b-dashboard-metrics__metrics_item--flagged:hover {
      background-color: #101010; }
  .b-dashboard-metrics__metrics_item--handled {
    background-color: rgba(0, 184, 66, 0.85); }
    .b-dashboard-metrics__metrics_item--handled:hover {
      background-color: #00b842; }

.b-dashboard-metrics__metrics_item_header_title {
  text-transform: uppercase;
  font-size: 0.8125rem;
  font-weight: 600;
  margin: 0 0 0.625rem 0; }

.b-dashboard-metrics__metrics_item_content, .b-dashboard-metrics__metrics_item_detail {
  text-align: left; }

.b-dashboard-metrics__metrics_item_content_value {
  color: #FFFFFF;
  display: inline-block;
  font-size: 2rem;
  margin: 0 0 0.9375rem 0; }

.b-dashboard-metrics__metrics_additional, .b-dashboard-metrics__metrics_percents {
  display: inline-block;
  color: #FFFFFF;
  font-size: 0.75rem; }

.b-dashboard-metrics__metrics_percents {
  background: #FFFFFF;
  color: #666666;
  font-size: 0.625rem;
  padding: 0.375rem;
  border-radius: 0.125rem;
  margin: 0 0 0 0.75rem; }

.b-dashboard-metrics__metrics_head_control {
  position: absolute;
  right: 0.6875rem;
  top: 0.6875rem;
  font-size: 18px !important;
  z-index: 2; }

.b-dashboard-metrics__grid-row {
  flex-grow: 1; }

.b-fixed-fee-selector {
  position: relative;
  width: 100%; }
  .b-fixed-fee-selector__control {
    width: 100%; }
    .b-fixed-fee-selector__control div[class$="-container"] {
      background: #FFFFFF;
      border-radius: 0.3125rem; }
      .b-fixed-fee-selector__control div[class$="-container"] div[class$="-control"] {
        background: transparent;
        border: 0;
        padding: 0 0.25rem;
        min-height: 1.875rem; }
        .b-fixed-fee-selector__control div[class$="-container"] div[class$="-control"] > div:first-child {
          visibility: hidden; }
        .b-fixed-fee-selector__control div[class$="-container"] div[class$="-control"] > div:last-child {
          border: 0.0625rem solid #ebebeb;
          border-radius: 0.3125rem; }
          .b-fixed-fee-selector__control div[class$="-container"] div[class$="-control"] > div:last-child div[class$="-indicatorContainer"] {
            margin: 0; }
      .b-fixed-fee-selector__control div[class$="-container"] div[class$="-indicatorContainer"] {
        color: #555555; }
  .b-fixed-fee-selector__control--disabled div[class$="-container"] div[class$="-indicatorContainer"] {
    color: rgba(0, 0, 0, 0.38); }
  .b-fixed-fee-selector__tooltip {
    position: absolute;
    right: -1.5625rem;
    top: 50%;
    margin-top: -0.625rem; }
  .b-fixed-fee-selector__error {
    color: #da2127;
    white-space: nowrap;
    position: absolute;
    bottom: -1.0625rem;
    left: 0;
    background: #FFFFFF; }
  .b-fixed-fee-selector__error--two_lines {
    bottom: -1.6875rem; }
  .b-fixed-fee-selector__hybrid {
    position: relative; }
  .b-fixed-fee-selector__control_input {
    position: absolute !important;
    left: 0;
    width: calc(100% - 25px);
    z-index: 1; }
    .b-fixed-fee-selector__control_input > div:first-child {
      padding-right: 0.3125rem !important; }
    .b-fixed-fee-selector__control_input fieldset {
      border: 1px solid #ebebeb !important; }
    .b-fixed-fee-selector__control_input input {
      padding: 0.3125rem 0 0.3125rem 0.3125rem !important;
      margin-top: 0.0625rem;
      background: #FFFFFF; }
    .b-fixed-fee-selector__control_input input::placeholder {
      font-size: 0.75rem;
      font-weight: normal; }
  .b-fixed-fee-selector__hybrid--error input {
    color: #da2127; }
  .b-fixed-fee-selector__control_input--no_dropdown {
    position: relative !important;
    width: 100%; }
  .b-fixed-fee-selector__clear_control {
    cursor: pointer; }

.b-fixed-percentage-selector {
  position: relative;
  top: 1rem;
  width: 9.375rem;
  min-width: 9.375rem !important;
  max-width: 9.375rem; }
  .b-fixed-percentage-selector--inbound {
    top: auto; }
  .b-fixed-percentage-selector__control {
    width: 100%; }
    .b-fixed-percentage-selector__control div[class$="-container"] {
      border-radius: 0.3125rem; }
      .b-fixed-percentage-selector__control div[class$="-container"] div[class$="-control"] {
        background: transparent;
        border: 0;
        padding: 0 0.25rem;
        min-height: 2rem; }
        .b-fixed-percentage-selector__control div[class$="-container"] div[class$="-control"] > div:first-child {
          visibility: hidden; }
        .b-fixed-percentage-selector__control div[class$="-container"] div[class$="-control"] > div:last-child {
          background: #FFFFFF;
          border: 0.0625rem solid #ebebeb;
          border-radius: 0.3125rem; }
          .b-fixed-percentage-selector__control div[class$="-container"] div[class$="-control"] > div:last-child div[class$="-indicatorContainer"] {
            margin: 0; }
      .b-fixed-percentage-selector__control div[class$="-container"] div[class$="-placeholder"] {
        text-transform: none !important;
        font-weight: normal !important;
        font-size: inherit !important; }
      .b-fixed-percentage-selector__control div[class$="-container"] div[class$="-indicatorContainer"] {
        color: #555555; }
  .b-fixed-percentage-selector__control--disabled div[class$="-container"] div[class$="-indicatorContainer"] {
    color: rgba(0, 0, 0, 0.38); }
  .b-fixed-percentage-selector__hybrid {
    position: relative;
    width: 9.375rem; }
  .b-fixed-percentage-selector__result_diminished_price {
    border: 0.0625rem solid #ebebeb;
    padding: 0.375rem 0.125rem !important;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 0.25rem;
    max-width: 6.25rem;
    outline: none; }
    .b-fixed-percentage-selector__result_diminished_price:disabled {
      background: #FFFFFF;
      opacity: 0.8; }
  .b-fixed-percentage-selector__control_input {
    position: absolute !important;
    left: 0;
    width: calc(100% - 25px);
    z-index: 1;
    background: #FFFFFF;
    border-radius: 0.25rem; }
    .b-fixed-percentage-selector__control_input > div:first-child {
      padding-right: 0.3125rem !important;
      border: 0.0625rem solid #ebebeb;
      border-radius: 0.25rem; }
      .b-fixed-percentage-selector__control_input > div:first-child:after, .b-fixed-percentage-selector__control_input > div:first-child:before {
        display: none; }
    .b-fixed-percentage-selector__control_input fieldset {
      border: 1px solid #ebebeb !important; }
    .b-fixed-percentage-selector__control_input input {
      padding: 0.3125rem 0 0.3125rem 0.3125rem !important;
      margin-top: 0.0625rem;
      background: #FFFFFF;
      font-weight: 600; }
    .b-fixed-percentage-selector__control_input input + div {
      position: relative; }
      .b-fixed-percentage-selector__control_input input + div:before {
        display: block;
        position: absolute;
        content: '%';
        left: -1.0625rem;
        font-weight: 600; }
    .b-fixed-percentage-selector__control_input input::placeholder {
      font-size: 0.75rem;
      font-weight: normal; }
  .b-fixed-percentage-selector__control_input--simple {
    position: absolute !important;
    left: 0;
    width: calc(100% - 25px);
    z-index: 1;
    background: #FFFFFF;
    border-radius: 0.25rem;
    border: 0.0625rem solid #ebebeb;
    font-size: 1rem;
    padding: 0.375rem 0.125rem !important; }
  .b-fixed-percentage-selector__control_input--no_dropdown {
    position: relative !important;
    width: 100%; }
  .b-fixed-percentage-selector__clear_control {
    cursor: pointer; }
  .b-fixed-percentage-selector__equal_sign, .b-fixed-percentage-selector__reduce_sign {
    margin: 0 0.3125rem;
    font-weight: 600;
    height: 1.5rem;
    padding: 0.4375rem 0 0 0; }
  .b-fixed-percentage-selector__reduce_sign, .b-fixed-percentage-selector__equal_sign {
    padding: 0.4375rem 0.3125rem;
    position: relative;
    font-size: 1rem; }
  .b-fixed-percentage-selector__result {
    font-style: italic;
    position: relative; }
    .b-fixed-percentage-selector__result:after {
      background: #555555;
      position: absolute;
      content: '';
      height: 0.0625rem;
      left: 0;
      right: 0;
      bottom: -0.125rem; }
  .b-fixed-percentage-selector__result_wrapper, .b-fixed-percentage-selector__control_wrapper {
    position: relative;
    min-width: 6.25rem; }
  .b-fixed-percentage-selector__input_label, .b-fixed-percentage-selector__default_product_price_label, .b-fixed-percentage-selector__result_label {
    position: absolute;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    color: rgba(0, 0, 0, 0.54);
    white-space: nowrap;
    transform: translate(0, 1.5px) scale(0.75);
    font-weight: 600;
    top: -1.1875rem;
    left: -0.8125rem; }
  .b-fixed-percentage-selector__input_label--disabled, .b-fixed-percentage-selector__result_label--disabled {
    color: rgba(0, 0, 0, 0.38); }
  .b-fixed-percentage-selector__default_product_price {
    font-size: 1rem;
    font-weight: 600;
    position: relative;
    top: 0.4375rem;
    white-space: nowrap; }
  .b-fixed-percentage-selector__default_product_price_label {
    top: -1.625rem;
    left: -0.375rem; }

.b-drop-zone {
  position: relative;
  width: 98%;
  margin: 0.4375rem 0 0 0; }
  .b-drop-zone__loader {
    z-index: 10; }
  .b-drop-zone__zone {
    min-height: 4.6875rem !important;
    outline: none;
    border: 0.0625rem solid !important;
    border-color: #ebebeb !important;
    background-color: transparent;
    border-radius: 0 !important;
    overflow: visible !important;
    height: 100%; }
    .b-drop-zone__zone .MuiDropzoneArea-textContainer {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      height: 100%; }
      .b-drop-zone__zone .MuiDropzoneArea-textContainer > svg {
        color: #5f5f5f;
        width: 2.1875rem;
        height: 2.1875rem; }
  .b-drop-zone__intro {
    font-size: 0.75rem !important;
    letter-spacing: 0.02rem !important;
    font-style: italic;
    color: #010101;
    margin-top: 0 !important;
    margin-bottom: 1.875rem !important; }
  .b-drop-zone__zone--loaded {
    border-color: #9EA1A9 !important; }
    .b-drop-zone__zone--loaded .b-drop-zone__intro {
      font-size: 0.625rem !important;
      margin-bottom: 1.625rem !important; }
  .b-drop-zone__container {
    width: 100% !important;
    margin: auto !important;
    align-content: space-around;
    justify-content: center; }
  .b-drop-zone__item {
    padding: 0.125rem !important; }
    .b-drop-zone__item > img {
      display: block;
      margin: 0 auto;
      height: 4.375rem; }
  .b-drop-zone__carousel_item {
    height: 6.375rem; }
  .b-drop-zone__carousel_item--carousel {
    border: 0.0625rem solid #010101;
    margin: 0 0.1875rem 0 0; }
  .b-drop-zone__carousel_item--single {
    border: 0;
    flex-basis: 50% !important; }
  .b-drop-zone__carousel_item:first-child {
    flex-basis: 75%; }
  .b-drop-zone__carousel_item:last-child {
    flex-basis: 25%; }
  .b-drop-zone__carousel_item--full {
    flex-basis: 100% !important; }
  .b-drop-zone__carousel_img_wrapper {
    position: relative; }
  .b-drop-zone__carousel_img_wrapper--single {
    border: 0.0625rem solid #010101; }
  .b-drop-zone__carousel_img_remove {
    cursor: pointer;
    position: absolute;
    color: #010101;
    opacity: 1;
    background: #C5C6CB;
    border-radius: 50%;
    top: 0.125rem;
    right: 0.3125rem; }
  .b-drop-zone__carousel_img {
    width: initial !important;
    max-width: 100%;
    height: 6.25rem;
    display: block;
    margin: 0 auto; }
  .b-drop-zone__carousel_img--big {
    border: 1px solid #ECECEE; }
  .b-drop-zone__carousel_ctrl {
    position: absolute;
    z-index: 2;
    top: calc(50% - 15px);
    width: 1.875rem;
    height: 1.875rem;
    cursor: pointer;
    stroke: #FFFFFF; }
  .b-drop-zone__carousel_ctrl--prev {
    left: 0.3125rem; }
  .b-drop-zone__carousel_ctrl--next {
    right: -0.125rem; }
  .b-drop-zone__images_label {
    text-align: left;
    padding: 0.625rem 0 0 0;
    font-weight: 600; }

.carousel .slide {
  background: transparent !important;
  border-left: 0.0625rem solid #9EA1A9;
  border-right: 0.0625rem solid #9EA1A9;
  margin: 0 0.0625rem !important; }

.b-reclamation-images-gallery {
  position: relative;
  width: 98%;
  margin: 0.4375rem 0 0 0;
  /* carousel styles rewriting */ }
  .b-reclamation-images-gallery__item {
    flex-basis: 100%; }
  .b-reclamation-images-gallery__item--with_the_images {
    flex-basis: 60%; }
  .b-reclamation-images-gallery__item--with_the_comment {
    flex-basis: 40%; }
  .b-reclamation-images-gallery__carousel_wrapper {
    width: 100%;
    height: 100%;
    border: 0.0625rem solid #010101; }
  .b-reclamation-images-gallery__carousel_img {
    width: initial !important;
    max-width: 100%;
    height: 6.25rem;
    display: block;
    margin: 0 auto; }
  .b-reclamation-images-gallery__carousel_ctrl {
    position: absolute;
    z-index: 2;
    top: calc(50% - 15px);
    width: 1.875rem;
    height: 1.875rem;
    cursor: pointer;
    stroke: #FFFFFF; }
  .b-reclamation-images-gallery__carousel_img--big {
    border: 1px solid #ECECEE; }
  .b-reclamation-images-gallery__carousel_ctrl--prev {
    left: 0.3125rem; }
  .b-reclamation-images-gallery__carousel_ctrl--next {
    right: -0.125rem; }
  .b-reclamation-images-gallery__comment {
    position: relative;
    border: 0.0625rem solid #010101;
    height: 100%;
    width: 98%;
    margin: 0 0 0 auto;
    font-style: italic;
    font-weight: 600;
    overflow: auto;
    text-align: left; }
  .b-reclamation-images-gallery__comment_label {
    text-align: left;
    padding: 0.625rem 0 0 0;
    font-weight: 600; }
  .b-reclamation-images-gallery__comment_text {
    padding: 0.625rem 0.3125rem; }
  .b-reclamation-images-gallery .carousel .slide {
    border-left: 0.0625rem solid #9EA1A9;
    border-right: 0.0625rem solid #9EA1A9; }
  .b-reclamation-images-gallery .carousel ul.control-dots {
    padding: 0; }
    .b-reclamation-images-gallery .carousel ul.control-dots .dot {
      background: #009E86;
      opacity: 1; }
    .b-reclamation-images-gallery .carousel ul.control-dots .dot.selected {
      background: #010101; }

.b-returns-table__clip_to_buffer_wrapper {
  position: relative;
  display: inline-block;
  width: auto; }

.b-returns-table__clip_to_buffer_ctrl {
  position: absolute;
  font-size: 0.875rem !important;
  background-color: transparent;
  background-position: 0 0;
  background-size: contain;
  background-repeat: no-repeat;
  border: 0;
  height: 0.875rem;
  width: 0.875rem;
  right: -1.25rem;
  cursor: pointer;
  top: 50%;
  margin-top: -0.4375rem; }

.b-returns-table__clip_to_buffer_tooltip_wrapper {
  position: relative; }

.b-returns-table__clip_to_buffer_tooltip {
  position: absolute;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 0.75rem;
  background: #9EA1A9;
  padding: 0.125rem 0.1875rem;
  border-radius: 0.25rem;
  top: 0;
  left: -2.75rem;
  line-height: 0.875rem;
  display: none;
  z-index: 10; }
  .b-returns-table__clip_to_buffer_tooltip.active {
    display: block; }

.b-returns-table__filter_item:first-child {
  width: 84%; }

.b-returns-table__filter_item:last-child {
  width: 16%; }

.b-log-event-popup {
  display: block;
  color: #010101;
  max-height: 18.75rem;
  overflow: auto;
  text-align: left;
  padding: 0.3125rem; }
  .b-log-event-popup__title {
    text-transform: uppercase;
    font-weight: bold;
    padding: 0 0 0 0; }
  .b-log-event-popup__body {
    height: 300px; }
  .b-log-event-popup__wrapper {
    display: flex;
    margin: 0.8125rem 0; }
  .b-log-event-popup__item {
    display: block; }
  .b-log-event-popup__item--date_time {
    width: 4.375rem; }
  .b-log-event-popup__item--icon {
    width: 4.375rem;
    position: relative; }
  .b-log-event-popup__item--event {
    width: calc(100% - 210px); }
  .b-log-event-popup__item--event_full {
    width: calc(100% - 140px); }
  .b-log-event-popup__item--status {
    width: 4.375rem; }
  .b-log-event-popup__date, .b-log-event-popup__time {
    display: block;
    width: 100%;
    font-style: italic; }
  .b-log-event-popup__date {
    font-size: 0.75rem; }
  .b-log-event-popup__time {
    font-size: 0.625rem; }
  .b-log-event-popup__icon_wrapper {
    position: relative;
    display: block;
    width: 100%; }
    .b-log-event-popup__icon_wrapper:before {
      content: '';
      position: absolute;
      width: 0.0625rem;
      height: 230%;
      background: #010101;
      left: 2.1875rem;
      top: -100%;
      z-index: 0; }
  .b-log-event-popup__icon {
    display: block;
    background: #FFFFFF;
    width: 1.9375rem;
    height: 1.9375rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    margin: 0 auto;
    position: relative;
    z-index: 1; }
  .b-log-event-popup__icon--error {
    background-position: 0 0; }
  .b-log-event-popup__icon--centra {
    background-position: -4.25rem 0; }
  .b-log-event-popup__icon--returnado {
    background-position: -6.625rem 0px;
    width: 1.9375rem;
    height: 1.875rem; }
  .b-log-event-popup__icon--logtrade {
    background-position: -135px 0; }
  .b-log-event-popup__event_info {
    display: block;
    background: #e0e0e0;
    position: relative;
    font-size: 0.875rem;
    padding: 0.4375rem 0.4375rem 0.4375rem 0.875rem;
    border-radius: 0.25rem;
    min-height: 2.0625rem;
    width: 100%; }
    .b-log-event-popup__event_info:before {
      content: '';
      position: absolute;
      width: 0.875rem;
      height: 0.875rem;
      background: #e0e0e0;
      top: 0.625rem;
      left: -0.4375rem;
      transform: rotate(45deg); }
  .b-log-event-popup__event_info--lined {
    text-decoration: line-through; }
  .b-log-event-popup__event_status {
    width: 4.375rem;
    display: block;
    text-align: right;
    padding: 0.1875rem 0 0 0; }
  .b-log-event-popup__event_status_wrapper {
    text-align: center;
    display: inline-block;
    width: 90%;
    position: relative;
    right: 0;
    top: 0.25rem; }
  .b-log-event-popup__event_status_wrapper--inline {
    display: inline;
    width: auto;
    top: auto; }
  .b-log-event-popup__event_status_wrapper--cleared, .b-log-event-popup__event_status_wrapper--clear {
    cursor: pointer;
    font-size: 0.5rem;
    border: 0.0625rem solid #010101;
    border-radius: 0.1875rem;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden; }
  .b-log-event-popup__event_status_wrapper--cleared {
    color: #010101;
    background: #9EA1A9; }
  .b-log-event-popup__event_status_wrapper--clear {
    color: #FFFFFF;
    background: #010101; }
  .b-log-event-popup__tooltip {
    cursor: pointer;
    top: 0.25rem;
    margin: 0 0 0 0.25rem; }
  .b-log-event-popup__tooltip_btn {
    background: transparent !important;
    padding: 0 !important; }
  .b-log-event-popup__left_align {
    text-align: left; }

.b-log-event-popup-static {
  position: relative;
  color: #010101;
  font-size: 0.75rem;
  font-weight: normal;
  background: #b8b8b8;
  border: 0.0625rem solid #a0a0a0;
  display: inline-block;
  padding: 0.625rem 0.625rem 0.25rem 0.625rem;
  border-radius: 0.25rem;
  margin: 0.625rem 0 0 0;
  max-width: 34.375rem; }
  .b-log-event-popup-static--hidden {
    display: none; }
  .b-log-event-popup-static__body_list {
    padding: 0 0.625rem;
    position: relative; }
    .b-log-event-popup-static__body_list > div:last-child {
      z-index: 1; }
    .b-log-event-popup-static__body_list:after {
      content: '';
      position: absolute;
      background: #b8b8b8;
      width: 0.625rem;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 0; }
  .b-log-event-popup-static__close_ctrl {
    cursor: pointer;
    position: absolute;
    top: -0.75rem;
    right: -0.75rem;
    background: #FFFFFF;
    border-radius: 50%; }
  .b-log-event-popup-static__event_status_wrapper--cleared, .b-log-event-popup-static__event_status_wrapper--clear {
    cursor: pointer;
    font-size: 0.5rem;
    font-weight: 600;
    border: 0.0625rem solid #010101;
    border-radius: 0.1875rem;
    padding: 0 0.3125rem 0.0625rem;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden; }
  .b-log-event-popup-static__event_status_wrapper--cleared {
    color: #010101;
    background: #9EA1A9; }
  .b-log-event-popup-static__event_status_wrapper--clear {
    color: #FFFFFF;
    background: #010101; }
  .b-log-event-popup-static__event_info {
    text-transform: uppercase; }
  .b-log-event-popup-static__event_info--lined {
    text-decoration: line-through; }
  .b-log-event-popup-static__p {
    margin: 0.375rem 0;
    font-size: 0.6875rem; }

/* Containers */
.b-loader__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.01);
  z-index: 999; }

.b-loader__item {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -0.75rem;
  margin-top: -0.75rem; }

.b-btn__wrapper {
  position: relative; }

.b-btn__loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -0.75rem;
  margin-top: -0.75rem; }

.b-loader__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.01);
  z-index: 999; }

.b-loader__item {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -0.75rem;
  margin-top: -0.75rem; }

.b-page-content {
  margin: 0 auto;
  padding: 0.3125rem;
  height: calc(100% - 235px);
  transition: padding 0.3s ease;
  position: relative;
  z-index: 0;
  /*&--orders.b-page-content--folded_open,
  &--returns.b-page-content--folded_open,
  &--customers.b-page-content--folded_open,
  &--inbound.b-page-content--folded_open,
  &--security.b-page-content--folded_open,
  &--item_data_report.b-page-content--folded_open {
    padding-left: rem(245px);
  }*/
  /*&--folded_open {
    padding-left: rem(223px);
  }*/ }
  .b-page-content--inbound, .b-page-content--returns, .b-page-content--orders, .b-page-content--customers, .b-page-content--security, .b-page-content--item_data_report, .b-page-content--dashboard, .b-page-content--management {
    height: calc(100% - 65px);
    padding: 1.5625rem; }
  .b-page-content__wrapper {
    height: 100%; }
  .b-page-content__body {
    margin: 0 0 12.5rem 0;
    padding: 0.9375rem 0 0 0;
    height: 100%; }

.b-partial-approval {
  min-width: 21.0625rem; }
  .b-partial-approval--returns {
    min-width: 100%; }
  .b-partial-approval__list {
    border: 1px solid #ECECEE;
    display: block;
    list-style-type: none;
    padding: 0 0.3125rem 0 0;
    text-align: left;
    margin: 0 auto;
    width: 98%; }
  .b-partial-approval__arrived_indicator_item {
    display: inline-block;
    width: 1.125rem;
    height: 1.125rem;
    border: 0.0625rem solid #00b842;
    margin: 0.8125rem 0.9375rem;
    background: #00b842;
    border-radius: 0.125rem; }
  .b-partial-approval__list_item {
    text-align: left;
    display: inline-block; }
  .b-partial-approval__list_item:last-child {
    padding: 0 0 0 0.1875rem; }
  .b-partial-approval__list_label {
    text-transform: uppercase;
    margin: 0 !important; }
    .b-partial-approval__list_label > span:last-child {
      font-size: 0.75rem; }
  .b-partial-approval__not_visible {
    visibility: hidden; }

.b-partial-approval--inbound .b-partial-approval__list {
  background: #FFFFFF;
  border: 1px solid #ECECEE;
  list-style-type: none;
  padding: 0 0.9375rem 0 0;
  text-align: left;
  margin: 0.375rem 0 0 0;
  transition: top 1s linear;
  width: 100%; }

.b-partial-approval--inbound .b-partial-approval__list_item {
  text-align: left;
  display: inline-block; }

.b-partial-approval--inbound .b-partial-approval__list_label {
  text-transform: uppercase;
  margin-right: 0 !important;
  margin-left: 0.3125rem !important; }
  .b-partial-approval--inbound .b-partial-approval__list_label > div:first-child {
    margin: 0.5rem; }
  .b-partial-approval--inbound .b-partial-approval__list_label > span:first-child {
    padding: 0.3125rem; }
  .b-partial-approval--inbound .b-partial-approval__list_label > span:last-child {
    font-size: 0.625rem; }

.b-partial-approval--inbound_expanded .b-partial-approval__list {
  margin: 0.875rem 0 0 0; }

.b-partial-approval__arrived_indicator_item {
  display: inline-block;
  width: 1.125rem;
  height: 1.125rem;
  border: 0.0625rem solid #00b842;
  background: #00b842;
  border-radius: 0.125rem; }

.b-partial-approval__arrived_indicator_item--red {
  border: 0.0625rem solid #da2127;
  background: #da2127; }

.b-partial-approval__arrived_indicator_item--yellow {
  border: 0.0625rem solid #d3ba2c;
  background: #d3ba2c; }

.b-send-to-cs-control {
  position: relative; }
  .b-send-to-cs-control__send_to_customer_control {
    background: transparent;
    box-shadow: none;
    cursor: pointer;
    display: inline-block;
    text-decoration: underline;
    font-size: 0.75rem;
    margin-top: 0.625rem; }
    .b-send-to-cs-control__send_to_customer_control:hover {
      text-decoration: none; }
  .b-send-to-cs-control__send_to_customer_loader {
    position: relative;
    left: -1.5rem; }
  .b-send-to-cs-control__send_to_customer_date {
    color: #C5C6CB;
    margin: 0.3125rem 0 0 0;
    font-size: 0.75rem;
    font-style: italic; }

.b-tooltip-wrapper {
  position: relative;
  display: inline-block; }
  .b-tooltip-wrapper__inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1; }

/* Hooks */
.b-page-not-found {
  height: inherit; }
  .b-page-not-found__controls {
    margin: 0 20px; }

/* High Order Component */
.b-app {
  text-align: center;
  background: #262933;
  height: inherit; }
  .b-app__header {
    position: relative;
    z-index: 1; }
  .b-app__body, .b-app__page {
    height: 100%; }
  .b-app__page_body {
    padding: 0.9375rem 0 0 0; }
  .b-app__body_item {
    position: relative;
    width: 98%;
    margin: 0 auto;
    border-radius: 0.3125rem;
    padding: 5px; }
  .b-app__return_item_loading {
    min-height: 12.5rem; }
  .b-app__content {
    height: inherit;
    position: relative;
    width: 100%;
    color: #555555;
    display: flex;
    overflow: hidden;
    flex-direction: row; }
  .b-app__page_controls {
    padding: 0.3125rem 0.3125rem 0 0.3125rem;
    position: relative;
    z-index: 501; }
  .b-app__layout {
    background: linear-gradient(to right, #2D323E 0%, #3C4252 100%);
    flex: 1 1; }
  .b-app__page_content_title {
    margin: 0; }
  .b-app__page_content_title--center_align {
    text-align: center; }
  .b-app__table_wrapper {
    background: #FFFFFF;
    padding: 0.625rem;
    border-radius: 0.3125rem;
    border: 1px solid #ECECEE; }

/* Pages */
.b-inbound-page {
  font-family: "Open Sans"; }
  .b-inbound-page__accordion {
    border: 0;
    background-color: transparent !important;
    box-shadow: none !important; }
    .b-inbound-page__accordion:before {
      display: none; }
  .b-inbound-page__accordion_main {
    display: block !important;
    width: 100%;
    padding: 0 1rem 1rem !important; }
  .b-inbound-page__main_search {
    padding: 0.9375rem;
    width: 70%;
    margin: 0 auto; }
  .b-inbound-page__additional_header {
    padding: 0.6875rem 0 0 0;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 7rem;
    text-transform: uppercase; }
  .b-inbound-page__description {
    font-size: 1.1875rem; }
  .b-inbound-page__body {
    padding: 0 2.1875rem;
    position: relative; }
  .b-inbound-page__search_wrapper {
    position: relative;
    width: 50%;
    max-width: 47.75rem;
    margin: 0.625rem auto 1.5625rem auto; }
  .b-inbound-page__list {
    display: block;
    padding: 0;
    margin: 0; }
  .b-inbound-page__order_item {
    list-style-type: none; }
  .b-inbound-page__bold_item {
    font-weight: 600; }
  .b-inbound-page__dashboard_img {
    display: block;
    width: 100%; }
  .b-inbound-page__confirm_control_body {
    border-radius: 0.3125rem;
    position: relative; }
  .b-inbound-page__confirm_control_btn {
    box-shadow: none !important;
    font-size: 1.0625rem !important;
    top: 0;
    bottom: 0;
    padding: 0;
    margin: 0 !important;
    width: 100%;
    min-width: 100% !important;
    left: 0; }
  .b-inbound-page__confirm_control_img {
    display: block;
    margin: 0 auto;
    max-width: 100%; }
  .b-inbound-page__customer_info {
    margin: 0 0 0.3125rem 0;
    position: relative; }
  .b-inbound-page__info_list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    flex-wrap: wrap;
    text-align: left; }
  .b-inbound-page__info_item {
    text-align: left;
    margin: 0 0 1.25rem 0;
    min-width: 20%; }
  .b-inbound-page__info_item_title {
    margin: 0 0 0.3125rem 0;
    font-weight: 600; }
  .b-inbound-page__info_item_title, .b-inbound-page__info_item_value {
    font-size: 0.75rem; }
  .b-inbound-page__info_item_value {
    max-width: 10rem; }
  .b-inbound-page__hided {
    display: none; }
  .b-inbound-page__visible {
    display: block; }
  .b-inbound-page__barcode {
    display: block;
    width: 99%;
    margin: 0 auto; }
  .b-inbound-page__no_order_found_control {
    font-family: "Open Sans";
    font-size: 1.5625rem; }
  .b-inbound-page__no_order_found_control_link {
    font-style: italic;
    color: inherit; }
  .b-inbound-page__loader_wrapper {
    background: rgba(0, 0, 0, 0.25); }
  .b-inbound-page__metrica {
    margin: 3.75rem 0 0 0; }
  .b-inbound-page__pagination_wrapper {
    border: 0.0625rem solid #ECECEE;
    background: #FFFFFF;
    border-radius: 0.3125rem;
    padding: 0 0.625rem 0.625rem 0.625rem; }
  .b-inbound-page__order_id_relation {
    color: #009E86; }

.b-inbound-item {
  background-color: #FFFFFF;
  margin: 0.625rem 0 1.25rem 0;
  border: 0.0625rem solid #ECECEE;
  border-radius: 0.3125rem;
  box-shadow: 0 0.0625rem 0.3125rem 0 rgba(0, 0, 0, 0.08), 0 0.125rem 0.125rem 0 rgba(0, 0, 0, 0.08), 0 0.1875rem 0.0625rem -0.125rem rgba(0, 0, 0, 0.08);
  transition: background-color 0.2s linear; }
  .b-inbound-item--removed {
    border: 0.1875rem solid #da2127; }
  .b-inbound-item--rejected {
    border: 0.1875rem solid #fa6532; }
  .b-inbound-item--arrived {
    border: 0.1875rem solid #009e86; }
  .b-inbound-item__header {
    border-radius: 0.3125rem 0.3125rem 0 0; }
  .b-inbound-item__head {
    background-color: transparent !important;
    box-shadow: none !important;
    border-bottom: 1px solid #ECECEE;
    padding: 0 0 0 1rem;
    position: relative;
    z-index: 1; }
  .b-inbound-item__body {
    font-size: 0.75rem; }
  .b-inbound-item__report_icon {
    opacity: 0.3; }
  .b-inbound-item__report_icon--active {
    opacity: 1; }
  .b-inbound-item__accordion {
    font-size: 0.875rem;
    background: transparent !important;
    box-shadow: none !important;
    border-top: 0.0625rem solid #ebebeb; }
    .b-inbound-item__accordion:before {
      background-color: transparent !important; }
  .b-inbound-item__accordion_control_panel {
    background-color: #FFFFFF !important;
    border: 0.0625rem solid #ECECEE !important;
    border-bottom: 0 !important;
    border-radius: 0.3125rem 0.3125rem 0 0 !important; }
    .b-inbound-item__accordion_control_panel > div:first-child {
      align-items: center; }
  .b-inbound-item__accordion_control_panel--collapsed {
    border-bottom: 0.0625rem solid #ECECEE !important;
    border-radius: 0.3125rem !important; }
  .b-inbound-item__accordion_details {
    display: block; }
  .b-inbound-item__accordion_details_block {
    background-color: #FFFFFF;
    margin: 0 -1rem 0.625rem;
    padding: 0 1.375rem;
    border: 0.0625rem solid #ECECEE;
    border-top: 0;
    border-radius: 0 0 0.3125rem 0.3125rem; }
  .b-inbound-item__accordion_item {
    position: relative;
    margin: 0 auto;
    width: 100%;
    padding: 0 0 0 2.5rem;
    font-size: 0.75rem; }
  .b-inbound-item__accordion_left_icon {
    top: 50%;
    left: 8px;
    position: absolute;
    transform: translateY(-50%) rotate(0deg);
    color: rgba(0, 0, 0, 0.54); }
  .b-inbound-item__counter {
    border-radius: 50%;
    font-family: 'Open Sans';
    background: #039be5;
    color: #FFFFFF;
    font-size: 0.875rem;
    line-height: 2.6875rem;
    text-align: center;
    margin-right: 0.625rem;
    width: 2.6875rem;
    height: 2.6875rem;
    position: absolute;
    left: -1.5625rem;
    top: -0.5rem; }
  .b-inbound-item__image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; }
  .b-inbound-item__img {
    display: block;
    max-width: 7.5rem;
    width: 100%; }
  .b-inbound-item__filters {
    font-size: 0.8125rem;
    width: 90%; }
    .b-inbound-item__filters div[class$="-container"]:hover {
      background: transparent; }
    .b-inbound-item__filters div[class$="-container"] div[class$="-placeholder"],
    .b-inbound-item__filters div[class$="-container"] div[class$="-singleValue"] {
      color: #303030;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.9375rem; }
    .b-inbound-item__filters div[class$="-container"] div[class$="-placeholder"] {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: calc(100% - 8px); }
    .b-inbound-item__filters .b-page-filter-items__items {
      text-align: center; }
    .b-inbound-item__filters .b-page-filter-items__item--number > div {
      border: 0.0625rem solid #ebebeb;
      background: #FFFFFF;
      border-radius: 0.3125rem; }
      .b-inbound-item__filters .b-page-filter-items__item--number > div:before {
        display: none; }
      .b-inbound-item__filters .b-page-filter-items__item--number > div > input {
        padding: 0.375rem 0 0.3125rem 0.1875rem; }
  .b-inbound-item__subblock {
    text-align: left;
    font-size: 0.875rem; }
  .b-inbound-item__textarea {
    display: block;
    width: 100%;
    margin: 0 auto;
    height: 4.6875rem;
    padding: 0.3125rem 0.4375rem;
    resize: none; }
  .b-inbound-item__textarea::placeholder {
    color: #C5C6CB;
    font-style: italic; }
  .b-inbound-item__common_info {
    padding: 1.25rem 0 0 0; }
  .b-inbound-item__common_info_item {
    margin: 0.1875rem 0; }
  .b-inbound-item__message_wrapper {
    display: block;
    padding: 0.5rem 1.5rem 1.5rem 1.5rem; }
  .b-inbound-item__message {
    font-size: 0.75rem;
    display: block;
    width: 100%; }
  .b-inbound-item__bottom_pusher {
    height: 0.9375rem; }
  .b-inbound-item__message_img {
    display: block;
    max-width: 100%;
    max-height: 7.5rem;
    margin: 0 auto; }
  .b-inbound-item__message_text {
    color: #303030;
    padding: 0.625rem;
    text-align: left;
    font-style: italic;
    font-weight: 600;
    height: 6.25rem; }
  .b-inbound-item__textarea, .b-inbound-item__message_text {
    border: 1px solid #e5e5e5;
    border-radius: 0 0 0.3125rem 0.3125rem; }
  .b-inbound-item__btn_wrapper {
    margin-block-start: 1rem;
    margin-block-end: 1rem; }
  .b-inbound-item__comment_label {
    display: block;
    margin: 1.25rem 0 0.625rem 0;
    font-weight: 600;
    text-align: left; }
  .b-inbound-item__send_to_customer_control {
    background: transparent;
    box-shadow: none;
    cursor: pointer;
    display: inline-block;
    text-decoration: underline;
    font-size: 0.75rem; }
    .b-inbound-item__send_to_customer_control:hover {
      text-decoration: none; }
  .b-inbound-item__title_status {
    padding: 0.3125rem;
    padding-right: 0.3125rem !important;
    color: #FFFFFF;
    border-radius: 0.3125rem;
    margin: -0.3125rem 0 0 0;
    min-width: 6.5625rem; }
  .b-inbound-item__accordion_summary {
    min-height: 0 !important; }
  .b-inbound-item__metrica_item {
    position: relative;
    cursor: pointer;
    max-width: 24.375rem;
    min-width: 6.25rem;
    width: 90%;
    margin: 0 auto;
    padding: 1.0625rem;
    border-radius: 0.5rem;
    transition: background-color 0.2s linear;
    height: 8.25rem; }
    .b-inbound-item__metrica_item--scanned {
      background-color: rgba(255, 116, 116, 0.85); }
      .b-inbound-item__metrica_item--scanned:hover {
        background-color: #ff7474; }
    .b-inbound-item__metrica_item--ontheway {
      background-color: rgba(211, 186, 44, 0.85); }
      .b-inbound-item__metrica_item--ontheway:hover {
        background-color: #d3ba2c; }
    .b-inbound-item__metrica_item--flagged {
      background-color: rgba(16, 16, 16, 0.85); }
      .b-inbound-item__metrica_item--flagged:hover {
        background-color: #101010; }
    .b-inbound-item__metrica_item--handled {
      background-color: rgba(0, 184, 66, 0.85); }
      .b-inbound-item__metrica_item--handled:hover {
        background-color: #00b842; }
  .b-inbound-item__metrica_item_header_title {
    text-transform: uppercase;
    font-size: 0.8125rem;
    font-weight: 600;
    margin: 0 0 0.625rem 0; }
  .b-inbound-item__metrica_item_content, .b-inbound-item__metrica_item_detail {
    text-align: left; }
  .b-inbound-item__metrica_item_content_value {
    color: #FFFFFF;
    display: inline-block;
    font-size: 2rem;
    margin: 0 0 0.9375rem 0; }
  .b-inbound-item__metrica_additional, .b-inbound-item__metrica_percents {
    display: inline-block;
    color: #FFFFFF;
    font-size: 0.75rem; }
  .b-inbound-item__metrica_percents {
    background: #FFFFFF;
    color: #666666;
    font-size: 0.625rem;
    padding: 0.375rem;
    border-radius: 0.125rem;
    margin: 0 0 0 0.75rem; }
  .b-inbound-item__metrica_head_control {
    position: absolute;
    right: 0.6875rem;
    top: 0.6875rem;
    font-size: 18px !important; }
  .b-inbound-item__refundable_price_error {
    display: none;
    color: #da2127; }
    .b-inbound-item__refundable_price_error.active {
      display: block; }
  .b-inbound-item__arrived_indicator {
    position: absolute;
    top: 0;
    right: 3.75rem;
    z-index: 1; }
  .b-inbound-item__product_img {
    display: block;
    margin: 0 auto;
    max-width: 12.5rem;
    width: 7.5rem; }
  .b-inbound-item__product_img--big {
    border: 1px solid #ECECEE; }
  .b-inbound-item__send_to_customer_date {
    color: #C5C6CB;
    margin: 0.3125rem 0 0 0;
    font-size: 0.75rem;
    font-style: italic; }
  .b-inbound-item__expand_icon {
    display: inline-block;
    border: 0.0625rem solid #cacaca;
    border-radius: 50%;
    background-color: #f5f5f5; }
  .b-inbound-item__dropdown_zone {
    margin-bottom: 0.625rem; }

.b-returns-item {
  font-family: "Open Sans";
  padding: 0.3125rem 0;
  margin-bottom: 0.9375rem;
  font-size: 0.75rem;
  position: relative;
  min-height: 9.375rem; }
  .b-returns-item__body {
    background-color: #FFFFFF;
    border-radius: 0.3125rem;
    border: 1px solid #ECECEE;
    padding: 0.3125rem 0; }
  .b-returns-item__head {
    position: relative !important;
    box-shadow: none !important;
    padding: 0 0.3125rem;
    z-index: 1 !important; }
  .b-returns-item__header, .b-returns-item__body_header {
    padding: 0.3125rem 0.9375rem; }
  .b-returns-item__header, .b-returns-item__body_header, .b-returns-item__info_list {
    text-align: left; }
  .b-returns-item__body_header {
    position: relative; }
  .b-returns-item__header {
    font-weight: 600;
    font-size: 0.9375rem; }
  .b-returns-item__delete_btn {
    color: #da2127; }
  .b-returns-item__info_list {
    list-style-type: none;
    flex-wrap: wrap;
    padding: 0 0 0 1.875rem; }
  .b-returns-item__info_item {
    margin: 0 0 1.25rem 0;
    min-width: 20%; }
  .b-returns-item__info_item_title, .b-returns-item__product_info_title, .b-returns-item__product_state_title {
    font-weight: 600;
    margin: 0 0 0.3125rem 0; }
  .b-returns-item__info_item_value {
    max-width: 10rem; }
  .b-returns-item__filters {
    font-size: 0.8125rem; }
    .b-returns-item__filters div[class$="-container"] div[class$="-placeholder"],
    .b-returns-item__filters div[class$="-container"] div[class$="-singleValue"] {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.9375rem; }
    .b-returns-item__filters div[class$="-placeholder"] {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: calc(100% - 8px); }
    .b-returns-item__filters input {
      font-weight: 600;
      padding: 2px 0.625rem; }
    .b-returns-item__filters .b-page-filter-items__item--number {
      padding: 1rem 0 0 0 !important; }
      .b-returns-item__filters .b-page-filter-items__item--number > div {
        border: 0.0625rem solid #ebebeb;
        background: #FFFFFF;
        border-radius: 0.3125rem;
        margin-top: 0 !important;
        padding: 0.25rem 0 0.1875rem 0; }
        .b-returns-item__filters .b-page-filter-items__item--number > div:before {
          display: none; }
  .b-returns-item__product {
    border: 1px solid #ECECEE;
    border-radius: 0.125rem;
    width: 98%;
    margin: 0 auto 0.9375rem auto;
    padding: 0 0 0.625rem 0; }
  .b-returns-item__product_body {
    padding: 1.875rem 0 0 0; }
  .b-returns-item__product_image {
    padding: 0.3125rem 0 0 0; }
  .b-returns-item__product_img {
    display: block;
    margin: 0 auto;
    max-width: 12.5rem;
    width: 10.125rem; }
  .b-returns-item__product_img--big {
    border: 1px solid #ECECEE; }
  .b-returns-item__product_counter {
    border-radius: 50%;
    background: #039be5;
    color: #FFFFFF;
    font-size: 0.875rem;
    line-height: 2.6875rem;
    text-align: center;
    margin-right: 0.625rem;
    width: 2.6875rem;
    height: 2.6875rem; }
  .b-returns-item__product_info_title, .b-returns-item__product_info_value {
    text-align: left; }
  .b-returns-item__product_info_item {
    padding: 0.625rem;
    margin: 0 0 0.3125rem 0; }
  .b-returns-item__state {
    text-align: right;
    padding: 0 0.9375rem 0 0; }
  .b-returns-item__product_state_value {
    padding: 0.125rem 0.3125rem;
    border-radius: 0.25rem; }
  .b-returns-item__btn {
    width: 80%;
    margin: 0 auto; }
  .b-returns-item__controls_item {
    margin: 0 0 0.9375rem 0; }
  .b-returns-item__accordion {
    margin: 21px auto 0 auto;
    box-shadow: none !important; }
  .b-returns-item__accordion_summary {
    min-height: 0 !important; }
  .b-returns-item__accordion--customer_reclamation_block {
    margin: 0 !important;
    border-top: 0.0625rem solid #ECECEE; }
    .b-returns-item__accordion--customer_reclamation_block:before {
      display: none; }
  .b-returns-item__accordion--customer_reclamation_block_collapsed {
    margin: 0 !important; }
  .b-returns-item__loader_wrapper {
    background: rgba(236, 236, 238, 0.7);
    z-index: 1; }
  .b-returns-item__product_control {
    margin: 0.625rem 0; }
  .b-returns-item__arrived_indicator {
    padding: 0.3125rem 0.625rem; }
  .b-returns-item__save_btn {
    width: 91% !important; }
  .b-returns-item__title_status {
    display: inline-block;
    padding: 0.3125rem;
    border-radius: 0.3125rem;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 0.75rem;
    color: #FFFFFF;
    margin-right: 0.3125rem; }
  .b-returns-item__shipment_control {
    margin: 0.625rem 0; }
  .b-returns-item__header_controls {
    display: flex;
    margin: 0 1.25rem 0 0; }
  .b-returns-item__accordion_item {
    position: relative;
    margin: 0 auto;
    width: 100%;
    padding-left: 2rem; }
  .b-returns-item__accordion_left_icon {
    top: 50%;
    left: 8px;
    position: absolute;
    transform: translateY(-50%) rotate(0deg);
    color: rgba(0, 0, 0, 0.54); }
  .b-returns-item__message {
    padding: 0 0.375rem;
    margin: -1.25rem 0 0 0;
    display: block;
    width: 100%; }
  .b-returns-item__comments_title--reclamation_image {
    text-align: center; }
  .b-returns-item__message_img {
    display: block;
    max-width: 100%;
    max-height: 7.5rem;
    margin: 0 auto; }
  .b-returns-item__message_text {
    color: #303030;
    padding: 0.625rem;
    margin: 0 0.9375rem 0 0;
    text-align: left;
    font-style: italic;
    font-weight: 600;
    height: 7.5rem; }
  .b-returns-item__textarea, .b-returns-item__message_text {
    border: 1px solid #e5e5e5;
    border-radius: 0 0 0.3125rem 0.3125rem; }
  .b-returns-item__message_text--reclamation_textarea_text {
    border-radius: 0;
    overflow-y: auto; }
  .b-returns-item__message_text--reclamation_textarea_text, .b-returns-item__comments_title--reclamation_textarea_title {
    margin: 0.4375rem 0 0.3125rem 0.375rem; }
  .b-returns-item__message_text--reclamation_textarea_text.active {
    border-color: #010101; }
  .b-returns-item__comments_title--reclamation_textarea_title {
    font-weight: 600; }
  .b-returns-item__message_wrapper {
    display: block;
    padding: 0 0.8125rem 0 0; }
  .b-returns-item__comments_title--reclamation_image {
    margin: 0 0 0.3125rem 0; }
  .b-returns-item__report_icon {
    opacity: 0.3;
    color: #da2127; }
  .b-returns-item__pusher {
    flex-grow: 1; }
  .b-returns-item__report_icon--active {
    opacity: 1; }
  .b-returns-item__send_to_customer_loader {
    position: relative;
    left: -1.5rem; }
  .b-returns-item__send_to_customer_control {
    background: transparent;
    box-shadow: none;
    cursor: pointer;
    display: inline-block;
    text-decoration: underline;
    font-size: 0.75rem;
    margin-top: 0.625rem; }
    .b-returns-item__send_to_customer_control:hover {
      text-decoration: none; }
  .b-returns-item__send_to_customer_date {
    color: #C5C6CB;
    margin: 0.3125rem 0 0 0;
    font-size: 0.75rem;
    font-style: italic; }
  .b-returns-item__shipping_icon {
    position: relative;
    display: inline-block;
    background-position: -4.25rem 0;
    width: 1.125rem;
    height: 1rem;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0.1875rem;
    margin: 0 0.625rem 0 0.9375rem; }
  .b-returns-item__save_btn {
    color: #FFFFFF !important;
    background-color: #009E86 !important; }
    .b-returns-item__save_btn:disabled {
      color: rgba(0, 0, 0, 0.26) !important;
      box-shadow: none !important;
      background-color: rgba(0, 0, 0, 0.12) !important; }

.b-orders-page__in_table_status {
  margin: 0; }

.b-orders-page__widget_btn_loader {
  position: relative;
  left: -1.5rem;
  top: 0.625rem; }

.b-returns-page__mismatch_warning {
  position: relative; }

.b-returns-page__filters div[class$="-singleValue"] {
  text-transform: capitalize; }

.b-returns-page__controls {
  padding: 0;
  margin: 0 0 0.9375rem 0; }

.b-returns-page__clear_search_ctrl {
  right: 0.1875rem; }

.b-returns-page__in_table_tooltip {
  position: absolute;
  top: 50%;
  left: -1.5625rem;
  margin-top: -0.6875rem; }

.b-returns-page__mismatch_warning_icon {
  color: #da2127; }

.b-security-page {
  font-family: "Open Sans"; }
  .b-security-page__additional_header {
    padding: 11px 0 0 0;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 7rem;
    text-transform: uppercase; }
  .b-security-page__description {
    font-size: 1rem;
    margin: 0.625rem auto 1.5625rem auto; }
    .b-security-page__description a {
      color: #009E86; }
  .b-security-page__barCode_btn {
    margin-bottom: 0.625rem; }

.b-item-data-report-page {
  font-family: "Open Sans"; }
  .b-item-data-report-page__additional_header {
    padding: 11px 0 0 0;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 7rem;
    text-transform: uppercase; }
  .b-item-data-report-page__description {
    font-size: 1rem;
    margin: 0.625rem auto 1.5625rem auto; }
  .b-item-data-report-page__filters_section {
    margin: 0.625rem 0; }
  .b-item-data-report-page__btn_wrapper {
    width: 12.5rem;
    margin: 0.625rem auto; }
  .b-item-data-report-page__counter {
    margin: 0 0 0.625rem; }
  .b-item-data-report-page__flex-grow {
    flex-grow: 1; }

.b-return-data-report-page {
  font-family: "Open Sans"; }
  .b-return-data-report-page__additional_header {
    padding: 11px 0 0 0;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 7rem;
    text-transform: uppercase; }
  .b-return-data-report-page__description {
    font-size: 1rem;
    margin: 0.625rem auto 1.5625rem auto; }
  .b-return-data-report-page__filters_section {
    margin: 0.625rem 0; }
  .b-return-data-report-page__btn_wrapper {
    width: 12.5rem;
    margin: 0.625rem auto; }
  .b-return-data-report-page__counter {
    margin: 0 0 0.625rem; }
  .b-return-data-report-page__flex-grow {
    flex-grow: 1; }

.b-analytics-page__paper {
  padding: 0.3125rem 0 0 0; }

.b-analytics-page__generate_controls {
  padding: 1.5625rem 1.0625rem 0 0; }

.b-analytics-page__generate_controls_item {
  width: 15%;
  margin: 0 0.625rem; }

.b-analytics-page__generate_controls_item:first-child {
  width: 70%; }

.b-analytics-page__generate_controls_btn {
  margin: 0 0.3125rem; }

.b-analytics-page__filters {
  justify-content: left; }

.b-analytics-page__additional_header {
  padding: 0.6875rem 0 0 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 7rem;
  text-transform: uppercase; }

.b-analytics-page__grid_container {
  flex-grow: 1; }

.b-analytics-page__btn_wrapper {
  display: inline-block;
  margin: 0 0.125rem;
  padding: 0.9375rem 0 0 0;
  width: 90%;
  max-width: 15rem;
  min-width: 9.375rem; }

.b-analytics-page__metric_wrapper {
  margin-top: 0.75rem;
  text-align: left; }

.b-analytics-page__metric_body, .b-analytics-page__metric_header {
  background: #FFFFFF;
  border-radius: 0.25rem;
  border: 0.0625rem solid #ebebeb; }

.b-analytics-page__metric_body {
  padding: 2.1875rem 3.4375rem; }

.b-analytics-page__tabs_list {
  list-style-type: none;
  margin: 0;
  padding: 0 0 0 0.625rem; }

.b-analytics-page__tabs_item {
  cursor: pointer;
  display: inline-block;
  padding: 1.25rem 1.6875rem;
  font-size: 0.875rem;
  color: #009E86;
  font-weight: 600;
  position: relative; }
  .b-analytics-page__tabs_item.active:after {
    background: #009E86;
    bottom: 0;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 0.125rem; }

.b-analytics-page__cards_wrapper {
  margin-bottom: 2.625rem;
  justify-content: space-around; }

.b-analytics-page__chart_wrapper {
  padding: 0.625rem;
  border: 0.0625rem solid #ebebeb;
  border-radius: 0.625rem;
  margin: 0 0 2.625rem 0; }

.b-analytics-page__chart_wrapper_header {
  border-bottom: 0.0625rem solid #ebebeb;
  padding: 1.5625rem 0 1.75rem 0;
  margin: 0 0 1.375rem 0; }

.b-analytics-page__chart_wrapper_header--simple {
  border-bottom: 0;
  margin: 0; }

.b-analytics-page__chart_wrapper_title {
  width: 12.5rem;
  font-weight: 700;
  padding: 0 0 0 1.0625rem;
  font-size: 1.125rem;
  letter-spacing: 0.05rem;
  white-space: nowrap; }

.b-analytics-page__chart_wrapper_controls {
  width: 100%;
  text-align: right; }

.b-analytics-page__chart_wrapper_list {
  position: relative;
  top: 0.25rem;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: inline-block; }

.b-analytics-page__chart_wrapper_list_item {
  cursor: pointer;
  display: inline-block;
  margin: 0 1.9375rem 0 0;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.05rem;
  position: relative; }
  .b-analytics-page__chart_wrapper_list_item:hover {
    color: #009E86; }

.b-analytics-page__chart_wrapper_list_item:first-child {
  margin: 0 2.1875rem 0 0; }

.b-analytics-page__chart_wrapper_list_item.active {
  color: #009E86; }
  .b-analytics-page__chart_wrapper_list_item.active:after {
    content: '';
    background: #009E86;
    position: absolute;
    left: -0.125rem;
    right: -0.125rem;
    bottom: -0.125rem;
    height: 1px; }

.b-analytics-page__bar_chart_argument_label {
  font-size: 0.6875rem; }

.b-analytics-card {
  border-radius: 0.625rem;
  border: 0.0625rem solid #ebebeb;
  border-top: 0;
  margin: 0 0.4375rem;
  width: 25%;
  min-width: 6.25rem;
  text-align: center;
  height: 13.875rem;
  overflow: hidden; }
  .b-analytics-card__top {
    border-top: 0.0625rem solid #ebebeb;
    border-radius: 0.625rem 0.625rem 0 0;
    background: #57c461;
    height: 0.875rem; }
  .b-analytics-card__top--green {
    background: #57c461; }
  .b-analytics-card__value--green {
    color: #57c461; }
  .b-analytics-card__top--orange {
    background: #ff973b; }
  .b-analytics-card__value--orange {
    color: #ff973b; }
  .b-analytics-card__top--black {
    background: #212121; }
  .b-analytics-card__value--black {
    color: #212121; }
  .b-analytics-card__body {
    border-bottom: 0.0625rem solid #ebebeb;
    padding: 0 0 0.9375rem 0;
    min-height: 8.4375rem; }
  .b-analytics-card__name {
    font-weight: 600;
    padding: 0.8125rem 0;
    max-height: 2.75rem;
    overflow: hidden;
    white-space: nowrap; }
  .b-analytics-card__value {
    font-size: 350%; }
  .b-analytics-card__value--values {
    font-size: 250%;
    padding: 1.375rem 0 1.0625rem 0;
    white-space: nowrap; }
  .b-analytics-card__footer {
    position: relative; }
  .b-analytics-card__titles {
    position: relative;
    width: 55%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 1%); }
  .b-analytics-card__titles--values {
    transform: translate(-50%, 15%); }
  .b-analytics-card__titles_list {
    margin: 0;
    padding: 0.5625rem 0 0 0;
    list-style-type: none; }
  .b-analytics-card__titles_item {
    font-size: 0.75rem;
    padding: 0.25rem 0;
    position: relative; }
    .b-analytics-card__titles_item:after {
      background: #000000;
      content: '';
      position: absolute;
      bottom: -0.0625rem;
      left: 50%;
      margin-left: -3.125rem;
      width: 6.25rem;
      height: 0.0625rem; }
  .b-analytics-card__titles_item:last-child:after {
    display: none; }

.b-analytics-overtime-chart__legend_list {
  padding: 0;
  margin: 0.625rem 0 0 0; }

.b-analytics-overtime-chart__legend_list_item_marker {
  position: absolute;
  width: 1rem;
  height: 1rem;
  top: 50%;
  margin-top: -0.5rem;
  left: -1.5625rem; }

.b-analytics-overtime-chart__legend_list_item {
  position: relative;
  display: inline-block;
  margin: 0 1.5625rem;
  font-size: 1rem;
  font-weight: 400;
  padding: 0.625rem 0; }

.b-analytics-overtime-chart__argument_axis_label {
  font-size: 0.75rem;
  color: red; }

@media (max-width: 1420px) {
  .b-analytics-card__value--rates {
    font-size: 250%;
    margin: 0.75rem 0 0 0; } }

@media (max-width: 1030px) {
  .b-analytics-card__value {
    font-size: 195%;
    margin: 0.75rem 0 0 0; }
  .b-analytics-card__value--rates {
    margin: 1.125rem 0 0 0; } }

@media (max-width: 880px) {
  .b-analytics-card__value {
    font-size: 150%;
    margin: 0.75rem 0 0 0; }
  .b-analytics-card__value--rates {
    margin: 1.125rem 0 0 0; } }

.b-management-page__subtitle {
  font-size: 1.125rem;
  font-weight: 600; }

.b-management-page__add_user_control {
  position: relative;
  border: 0.125rem solid #009E86;
  padding: 0.1875rem 0.9375rem 0.125rem 1.875rem;
  top: -0.0625rem;
  color: #009E86;
  font-size: 0.875rem;
  margin: 0 0 0 0.625rem;
  cursor: pointer;
  opacity: 0.9; }
  .b-management-page__add_user_control:hover {
    opacity: 1; }

.b-management-page__add_user_control_icon {
  position: absolute;
  top: 50%;
  margin-top: -0.6875rem;
  left: 0.25rem; }

.b-management-page__tabs {
  margin: 0 0 0.625rem 0; }

.b-management-page__tabs_item {
  cursor: pointer; }
  .b-management-page__tabs_item:hover {
    text-decoration: underline; }

.b-management-page__tabs_item--middle {
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
  font-weight: 600; }

.b-management-page__tabs_item.active {
  font-weight: 600; }

.b-management-page__rotate {
  transform: rotate(45deg); }

.b-management-page__table_control {
  cursor: pointer;
  opacity: 0.8;
  position: relative;
  top: -0.1875rem; }
  .b-management-page__table_control:hover {
    opacity: 1; }

.b-management-page__table_control--own {
  cursor: auto;
  opacity: 1; }

.b-management-page__table_control--green {
  color: #00b842;
  opacity: 1; }

.b-management-page__table_control--red {
  color: #da2127;
  opacity: 1; }

.b-management-page__popup_content {
  display: block;
  font-size: 0.875rem; }

.b-management-page__popup_content_item {
  justify-content: space-between;
  margin: 0 0 1.5625rem 0; }

.b-management-page__popup_content_control_label {
  font-weight: 500;
  margin-right: 0.3125rem;
  color: #555555; }

.b-management-page__popup_content_control_label, .b-management-page__popup_content_control_input {
  display: inline-block; }

.b-management-page__popup_content_control_input {
  outline: none;
  border: 0.0625rem solid #C5C6CB;
  padding: 0.125rem 0.3125rem;
  color: #555555; }
  .b-management-page__popup_content_control_input.error:focus, .b-management-page__popup_content_control_input.error {
    border-color: #da2127; }
  .b-management-page__popup_content_control_input:focus {
    border-color: #9EA1A9; }
  .b-management-page__popup_content_control_input::placeholder {
    font-style: italic; }

.b-management-page__popup_content_item--block, .b-management-page__popup_content_description {
  display: block; }

.b-management-page__popup_content_control_wrapper--single {
  flex-basis: 100%;
  text-align: left; }

.b-management-page__popup_content_description {
  font-style: italic;
  font-size: 0.75rem; }

.b-management-page__popup_content_control_label--single {
  flex-basis: 4.25rem; }

.b-management-page__popup_content_control_input--single {
  width: 100%; }

.b-management-page__popup_content_input_wrapper {
  position: relative; }

.b-management-page__popup_content_input_wrapper--single {
  display: block;
  flex-basis: calc(100% - 68px); }

.b-management-page__popup_content_error_text {
  position: absolute;
  bottom: -1.4375rem;
  left: 0;
  font-size: 0.6875rem;
  color: #da2127; }

.b-management-page__popup_content_control_label {
  text-align: left; }

.b-management-page__popup_content_control_label--fixed_min_width {
  min-width: 4.1875rem; }

/* GENERAL */
.b-page__statuses_table_item {
  border-radius: 2px;
  padding: 2px 4px; }

.b-page__statuses_table_item {
  margin: 0 0.125rem; }

.b-page-table__order_list {
  margin: 0;
  padding: 0;
  flex-wrap: wrap; }

.b-page-table__order_list_item {
  list-style-type: none;
  padding: 0 0.3125rem; }

.b-pagination {
  padding: 0.625rem 0 0 0;
  font-size: 0.875rem; }
  .b-pagination__item--pusher {
    flex-grow: 1; }
  .b-pagination__item {
    padding: 0.125rem; }
  .b-pagination__item--prev {
    text-align: left; }
  .b-pagination__item--next {
    text-align: right; }
  .b-pagination__btn {
    width: 100%;
    max-width: 3.5rem; }
  .b-pagination__item--addinfo {
    text-align: center;
    font-weight: 600; }
  .b-pagination__padding_top {
    padding: 0.5rem 0; }
  .b-pagination__dropdown_control {
    text-align: center; }
    .b-pagination__dropdown_control > div > div:first-child {
      padding-left: 1.125rem; }

.b-mismatch-tooltip__mismatch_warning_icon {
  fill: #da2127 !important;
  position: relative;
  top: 0.4375rem;
  left: -0.125rem; }

.b-mismatch-tooltip__mismatch_warning_icon--inbound {
  top: 0; }

/* Overrides */
.css-bgvzuu-indicatorSeparator {
  width: 0 !important; }

.ReactTable {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.8125rem;
  text-align: left; }
  .ReactTable .rt-thead .rt-resizable-header-content {
    font-weight: 600; }
  .ReactTable .rt-thead.-header {
    box-shadow: none !important; }
  .ReactTable .rt-tbody .rt-td {
    text-align: center; }
  .ReactTable .rt-noData {
    z-index: 0; }

.management-table .ReactTable .rt-tbody .rt-tr {
  height: 1.75rem; }

.b-grid__container {
  flex-grow: 1; }

.b-custom-tooltip-icon {
  transform: rotate(180deg); }

div[role='tooltip'] * {
  font-size: 0.75rem; }

/* Range Date Picker */
.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside),
.InputFromTo-to .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: rgba(0, 158, 134, 0.1) !important;
  color: #009E86; }

.InputFromTo .DayPicker-Day,
.InputFromTo-to .DayPicker-Day {
  border-radius: 0 !important; }

.InputFromTo .DayPicker-Day--start,
.InputFromTo-to .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important; }

.InputFromTo .DayPicker-Day--today,
.InputFromTo-to .DayPicker-Day--today {
  color: #009E86 !important; }

.InputFromTo .DayPicker-Day--end,
.InputFromTo-to .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important; }

.InputFromTo .DayPickerInput-Overlay,
.InputFromTo-to .DayPickerInput-Overlay {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  width: 34.375rem;
  z-index: 2; }

/*  General */
/*#fa6532 rejectState
#009e86 arrivedState
#aaaaaa pendingState
#da2127 removedState*/
.b-state-color {
  color: #FFFFFF !important; }

.b-reject-state-bg-color {
  background-color: #fa6532 !important; }

.b-arrived-state-bg-color {
  background-color: #009e86 !important; }

.b-pending-state-bg-color {
  background-color: #aaaaaa !important; }

.b-removed-state-bg-color {
  background-color: #da2127 !important; }

.b-btn-received {
  background-color: rgba(0, 158, 134, 0.9) !important; }
  .b-btn-received:hover {
    background-color: #009e86 !important; }

.b-btn-rejected {
  background-color: rgba(250, 101, 50, 0.9) !important; }
  .b-btn-rejected:hover {
    background-color: #fa6532 !important; }

.b-btn-received-inactive {
  background-color: rgba(0, 158, 134, 0.5) !important; }
  .b-btn-received-inactive:hover {
    background-color: #009e86 !important; }

.b-btn-rejected-inactive {
  background-color: rgba(250, 101, 50, 0.5) !important; }
  .b-btn-rejected-inactive:hover {
    background-color: #fa6532 !important; }

.b-toolbar {
  padding: 0 1.5rem;
  display: flex;
  position: relative;
  align-items: center;
  min-height: 4rem; }
