.b-reclamation-images-gallery {
  position: relative;
  width: 98%;
  margin: rem(7px) 0 0 0;

  &__item {
    flex-basis: 100%;
  }

  &__item--with_the_images {
    flex-basis: 60%;
  }

  &__item--with_the_comment {
    flex-basis: 40%;
  }

  &__carousel_wrapper {
    width: 100%;
    height: 100%;
    border: rem(1px) solid $black;
  }

  &__carousel_img {
    width: initial !important;
    max-width: 100%;
    height: rem(100px);
    display: block;
    margin: 0 auto;
  }

  &__carousel_ctrl {
    position: absolute;
    z-index: 2;
    top: calc(50% - 15px);
    width: rem(30px);
    height: rem(30px);
    cursor: pointer;
    stroke: $white;
  }

  &__carousel_img--big {
    border: 1px solid $F50;
  }

  &__carousel_ctrl--prev {
    left: rem(5px);
  }

  &__carousel_ctrl--next {
    right: rem(-2px);
  }

  &__comment {
    position: relative;
    border: rem(1px) solid $black;
    height: 100%;
    width: 98%;
    margin: 0 0 0 auto;
    font-style: italic;
    font-weight: 600;
    overflow: auto;
    text-align: left;
  }

  &__comment_label {
    text-align: left;
    padding: rem(10px) 0 0 0;
    font-weight: 600;
  }

  &__comment_text {
    padding: rem(10px) rem(5px);
  }

  /* carousel styles rewriting */

  .carousel {

    .slide {
      border-left: rem(1px) solid $F200;
      border-right: rem(1px) solid $F200;
    }

    ul.control-dots {
      padding: 0;

      .dot {
        background: $main-first-color;
        opacity: 1;
      }

      .dot.selected {
        background: $black;
      }
    }

  }

}