.b-page-header {
  display: flex;
  flex-direction: column;
  padding: rem(15px) rem(24px);
  transition: padding-left 0.3s ease;

  &--padding_left {
    padding-left: rem(240px);
  }

  &__additional {
    display: flex;
    align-content: center;
    text-align: left;
    align-items: inherit;
  }

  &__breadcrumbs {
    margin: 0 0 rem(15px) 0
  }

  &__controls {
    flex-grow: 1;
  }

}