.b-returns-item {
  font-family: "Open Sans";
  padding: rem(5px) 0;
  margin-bottom: rem(15px);
  font-size: rem(12px);
  position: relative;
  min-height: rem(150px);

  &__body {
    background-color: $light;
    border-radius: rem(5px);
    border: 1px solid $F50;
    padding: rem(5px) 0;
  }

  &__head {
    position: relative !important;
    box-shadow: none !important;
    padding: 0 rem(5px);
    z-index: 1 !important;
  }

  &__header,
  &__body_header {
    padding: rem(5px) rem(15px);
  }

  &__header,
  &__body_header,
  &__info_list {
    text-align: left;
  }

  &__body_header {
    position: relative;
  }

  &__header {
    font-weight: 600;
    font-size: rem(15px);
  }

  &__delete_btn {
    color: $inboundRemoveColor;
  }

  &__info_list {
    list-style-type: none;
    flex-wrap: wrap;
    padding: 0 0 0 rem(30px);
  }

  &__info_item {
    margin: 0 0 rem(20px) 0;
    min-width: 20%;
  }

  &__info_item_title,
  &__product_info_title,
  &__product_state_title {
    font-weight: 600;
    margin: 0 0 rem(5px) 0;
  }

  &__info_item_value {
    max-width: rem(160px);
  }

  &__filters {
    font-size: rem(13px);

    div[class$="-container"] {

      div[class$="-placeholder"],
      div[class$="-singleValue"] {
        text-transform: uppercase;
        font-weight: 600;
        font-size: rem(15px);
      }

    }

    div[class$="-placeholder"] {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: calc(100% - 8px);
    }

    input {
      font-weight: 600;
      padding: 2px rem(10px);
    }

    .b-page-filter-items {

      &__item--number {
        padding: rem(16px) 0 0 0 !important;

        > div {
          border: rem(1px) solid $F80;
          background: $main-color;
          border-radius: rem(5px);
          margin-top: 0 !important;
          padding: rem(4px) 0 rem(3px) 0;
          &:before {
            display: none;
          }
        }
      }

    }

  }

  &__product {
    border: 1px solid $F50;
    border-radius: rem(2px);
    width: 98%;
    margin: 0 auto rem(15px) auto;
    padding: 0 0 rem(10px) 0
  }

  &__product_header {

  }

  &__product_body {
    padding: rem(30px) 0 0 0;
  }

  &__product_image {
    padding: rem(5px) 0 0 0;
  }

  &__product_img {
    display: block;
    margin: 0 auto;
    max-width: rem(200px);
    width: rem(162px);
  }

  &__product_img--big {
    border: 1px solid $F50;
  }

  &__product_counter {
    border-radius: 50%;
    background: $blue;
    color: $main-color;
    font-size: rem(14px);
    line-height: rem(43px);
    text-align: center;
    margin-right: rem(10px);
    width: rem(43px);
    height: rem(43px);
  }

  &__product_info_title,
  &__product_info_value {
    text-align: left;
  }

  &__product_info_item {
    padding: rem(10px);
    margin: 0 0 rem(5px) 0;
  }

  &__state {
    text-align: right;
    padding: 0 rem(15px) 0 0;
  }

  &__product_state_value {
    padding: rem(2px) rem(5px);
    border-radius: rem(4px);
  }

  &__btn {
    width: 80%;
    margin: 0 auto;
  }

  &__controls_item {
    margin: 0 0 rem(15px) 0;
  }

  &__accordion {
    margin: 21px auto 0 auto;
    box-shadow: none !important;
  }

  &__accordion_summary {
    min-height: 0 !important;
  }

  &__accordion--customer_reclamation_block {
    margin: 0 !important;
    border-top: rem(1px) solid $F50;
    &:before {
      display: none;
    }
  }

  &__accordion--customer_reclamation_block_collapsed {
    margin: 0 !important;
  }

  &__loader_wrapper {
    background: rgba(236,236,238,0.7);
    z-index: 1;
  }

  &__product_control {
    margin: rem(10px) 0;
  }

  &__arrived_indicator {
    padding: rem(5px) rem(10px);
  }

  &__save_btn {
    width: 91% !important;
  }

  &__title_status {
    display: inline-block;
    padding: rem(5px);
    border-radius: rem(5px);
    text-transform: uppercase;
    font-weight: normal;
    font-size: rem(12px);
    color: $main-color;
    margin-right: rem(5px);
  }

  &__shipment_control {
    margin: rem(10px) 0;
  }

  &__header_controls {
    display: flex;
    margin: 0 rem(20px) 0 0;
  }

  &__accordion_item {
    position: relative;
    margin: 0 auto;
    width: 100%;
    padding-left: rem(32px);
  }

  &__accordion_left_icon {
    top: 50%;
    left: 8px;
    position: absolute;
    transform: translateY(-50%) rotate(0deg);
    color: rgba(0, 0, 0, 0.54);
  }

  &__message {
    padding: 0 rem(6px);
    margin: rem(-20px) 0 0 0;
    display: block;
    width: 100%;
  }

  &__message_image {

  }

  &__comments_title--reclamation_image {
    text-align: center;
  }

  &__message_img {
    display: block;
    max-width: 100%;
    max-height: rem(120px);
    margin: 0 auto;
  }

  &__message_text {
    color: $dark;
    padding: rem(10px);
    margin: 0 rem(15px) 0 0;
    text-align: left;
    font-style: italic;
    font-weight: 600;
    height: rem(120px);
  }

  &__textarea,
  &__message_text {
    border: 1px solid $inboundLightGrayColor;
    border-radius: 0 0 rem(5px) rem(5px);
  }

  &__message_text--reclamation_textarea_text {
    border-radius: 0;
    overflow-y: auto;
  }

  &__message_text--reclamation_textarea_text,
  &__comments_title--reclamation_textarea_title {
    margin: rem(7px) 0 rem(5px) rem(6px);
  }

  &__message_text--reclamation_textarea_text.active {
    border-color: $black;
  }

  &__comments_title--reclamation_textarea_title {
    font-weight: 600;
  }

  &__message_wrapper {
    display: block;
    padding: 0 rem(13px) 0 0;
  }

  &__comments_title--reclamation_image {
    margin: 0 0 rem(5px) 0;
  }

  &__report_icon {
    opacity: 0.3;
    color: $inboundRemoveColor;
  }

  &__pusher {
    flex-grow: 1;
  }

  &__report_icon--active {
    opacity: 1;
  }

  &__send_to_customer_loader {
    position: relative;
    left:rem(-24px);
  }

  &__send_to_customer_control {
    background: transparent;
    box-shadow: none;
    cursor: pointer;
    display: inline-block;
    text-decoration: underline;
    font-size: rem(12px);
    margin-top: rem(10px);

    &:hover {
      text-decoration: none;
    }
  }

  &__send_to_customer_date {
    color: $F100;
    margin: rem(5px) 0 0 0;
    font-size: rem(12px);
    font-style: italic;
  }

  &__shipping_icon {
    position: relative;
    display: inline-block;
    background-position: rem(-68px) 0;
    width: rem(18px);
    height: rem(16px);
    background-repeat: no-repeat;
    background-size: cover;
    top: rem(3px);
    margin: 0 rem(10px) 0 rem(15px);
  }

  &__save_btn {
    color: $white !important;
    background-color: $main-first-color !important;

    &:disabled {
      color: rgba(0, 0, 0, 0.26) !important;
      box-shadow: none !important;
      background-color: rgba(0, 0, 0, 0.12) !important;
    }
  }
}