.b-log-event-popup {
  display: block;
  color: $black;
  max-height: rem(300px);
  overflow: auto;
  text-align: left;
  padding: rem(5px);

  &__title {
    text-transform: uppercase;
    font-weight: bold;
    padding: 0 0 0 0;
  }

  &__body {
    height: 300px;
  }

  &__wrapper {
    display: flex;
    margin: rem(13px) 0;
  }

  &__item {
    display: block;
  }

  &__item--date_time {
    width: rem(70px);
  }

  &__item--icon {
    width: rem(70px);
    position: relative;
  }

  &__item--event {
    width: calc(100% - 210px);;
  }

  &__item--event_full {
    width: calc(100% - 140px);;
  }

  &__item--status {
    width: rem(70px);
  }

  &__date,
  &__time {
    display: block;
    width: 100%;
    font-style: italic;
  }

  &__date {
    font-size: rem(12px);
  }

  &__time {
    font-size: rem(10px);
  }

  &__icon_wrapper {
    position: relative;
    display: block;
    width: 100%;

    &:before {
      content: '';
      position: absolute;
      width: rem(1px);
      height: 230%;
      background: $black;
      left: rem(35px);
      top: -100%;
      z-index: 0;
    }
  }

  &__icon {
    display: block;
    background: $white;
    width: rem(31px);
    height: rem(31px);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }

  &__icon--error {
    background-position: 0 0;
  }

  &__icon--centra {
    background-position: rem(-68px) 0;
  }

  &__icon--returnado {
    background-position: rem(-106px) 0px;
    width: rem(31px);
    height: rem(30px);
  }

  &__icon--logtrade {
    background-position: -135px 0;
  }

  &__event_info {
    display: block;
    background: $returnEventLogBackgroundColor;
    position: relative;
    font-size: rem(14px);
    padding: rem(7px) rem(7px) rem(7px) rem(14px);
    border-radius: rem(4px);
    min-height: rem(33px);
    width: 100%;

    &:before {
      content: '';
      position: absolute;
      width: rem(14px);
      height: rem(14px);
      background: $returnEventLogBackgroundColor;
      top: rem(10px);
      left: rem(-7px);
      transform: rotate(45deg);
    }
  }

  &__event_info--lined {
    text-decoration: line-through;
  }

  &__event_status {
    width: rem(70px);
    display: block;
    text-align: right;
    padding: rem(3px) 0 0 0;
  }

  &__event_status_wrapper {
    text-align: center;
    display: inline-block;
    width: 90%;
    position: relative;
    right: 0;
    top: rem(4px);
  }

  &__event_status_wrapper--inline {
    display: inline;
    width: auto;
    top:auto;
  }

  &__event_status_wrapper--cleared,
  &__event_status_wrapper--clear {
    cursor: pointer;
    font-size: rem(8px);
    border: rem(1px) solid $black;
    border-radius: rem(3px);
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
  }

  &__event_status_wrapper--cleared {
    color: $black;
    background: $F200;
  }

  &__event_status_wrapper--clear {
    color: $main-color;
    background: $black;
  }

  &__tooltip {
    cursor: pointer;
    top: rem(4px);
    margin: 0 0 0 rem(4px);
  }

  &__tooltip_btn {
    background: transparent !important;
    padding: 0 !important;
  }

  &__left_align {
    text-align: left;
  }
}

.b-log-event-popup-static {
  position: relative;
  color: $black;
  font-size: rem(12px);
  font-weight: normal;
  background: #b8b8b8;
  border: rem(1px) solid #a0a0a0;
  display: inline-block;
  padding: rem(10px) rem(10px) rem(4px) rem(10px);
  border-radius: rem(4px);
  margin: rem(10px) 0 0 0;
  max-width: rem(550px);

  &--hidden {
    display: none;
  }

  &__body_list {
    padding: 0 rem(10px);
    position: relative;

    > div:last-child {
      z-index: 1;
    }

    &:after {
      content: '';
      position: absolute;
      background: #b8b8b8;
      width: rem(10px);
      top:0;
      bottom: 0;
      right: 0;
      z-index: 0;
    }
  }

  &__close_ctrl {
    cursor: pointer;
    position: absolute;
    top: rem(-12px);
    right: rem(-12px);
    background: $white;
    border-radius: 50%;
  }

  &__event_status_wrapper--cleared,
  &__event_status_wrapper--clear {
    cursor: pointer;
    font-size: rem(8px);
    font-weight: 600;
    border: rem(1px) solid $black;
    border-radius: rem(3px);
    padding: 0 rem(5px) rem(1px);
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
  }

  &__event_status_wrapper--cleared {
    color: $black;
    background: $F200;
  }

  &__event_status_wrapper--clear {
    color: $main-color;
    background: $black;
  }

  &__event_info {
    text-transform: uppercase;
  }

  &__event_info--lined {
    text-decoration: line-through;
  }

  &__p {
    margin: rem(6px) 0;
    font-size: rem(11px);
  }
}