.b-returns-comments-areas {
  margin: rem(15px) 0 0 0;

  &__comments_title {
    text-align: left;
    padding: rem(10px) 0;
    font-weight: 600;
  }

  &__product_comments_block {
    margin: rem(15px) 0 0 0;
  }

  &__comments {
    padding: 0 rem(15px) 0 0;
    display: flex;
    width: 100%;
  }

  &__comments_customer,
  &__comments_internal {
    width: 100%;
  }

  &__comments_textarea {
    border: rem(1px) solid $F50;
    display: block;
    height: rem(75px);
    padding: rem(5px);
    width: 98%;
    resize: none;
  }

  &__comments_textarea.active {
    border-color: $black;
  }

  &__comments_textarea::placeholder {
    color: $F100;
    font-style: italic;
  }
}