.b-fixed-fee-selector {
    position: relative;
    width: 100%;

  &__control {
    width: 100%;
    div[class$="-container"] {
      background: $main-color;
      //border: rem(1px) solid $F80;
      border-radius: rem(5px);

      div[class$="-control"] {
        background: transparent;
        border: 0;
        padding: 0 rem(4px);
        min-height: rem(30px);

        > div:first-child {
          visibility: hidden;
        }

        > div:last-child {
          border: rem(1px) solid $F80;
          border-radius: rem(5px);

          div[class$="-indicatorContainer"] {
            margin: 0;
          }
        }

      }

      div[class$="-indicatorContainer"] {
        color: $main-text-color;
      }
    }
  }

  &__control--disabled {
    div[class$="-container"] {
      div[class$="-indicatorContainer"] {
        color: $disabledControlsColor;
      }
    }
  }

  &__tooltip {
    position: absolute;
    right: rem(-25px);
    top: 50%;
    margin-top: rem(-10px);
  }

  &__error {
    color: $inboundRemoveColor;
    white-space: nowrap;
    position: absolute;
    bottom: rem(-17px);
    left: 0;
    background: $main-color;
  }

  &__error--two_lines {
    bottom: rem(-27px);
  }

  &__hybrid {
    position: relative;
  }

  &__control_input {
    position: absolute !important;
    left: 0;
    width: calc(100% - 25px);
    z-index: 1;

    > div:first-child {
      padding-right: rem(5px) !important;
    }

    fieldset {
      border: 1px solid $F80 !important;
    }

    input {
      padding: rem(5px) 0 rem(5px) rem(5px) !important;
      margin-top: rem(1px);
      background: $main-color;
    }

    input::placeholder {
      font-size: rem(12px);
      font-weight: normal;
    }

  }

  &__hybrid--error {
    input {
      color: $inboundRemoveColor;
    }
  }

  &__control_input--no_dropdown {
    position: relative !important;
    width: 100%;
  }

  &__clear_control {
    cursor: pointer;
  }

}
