.b-left-menu {

  &__folded {
    position: absolute;
    width: rem(62px);
    min-width: rem(62px);
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    flex: 1 1 auto;
    height: 100%;
    z-index: 4;
    transition: width 0.2s ease-in, min-width 0.2s ease-in;
    background-color: $main-navigation-color;
  }

  &__folded--open {
    width: rem(280px);
    min-width: rem(280px);
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 0 rem(8px);
    justify-content: flex-start;
  }

  &__folded--paper {
    background-color: $main-navigation-color !important;
    width: rem(280px);
  }
}