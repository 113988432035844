.b-return-description {
  text-align: left;
  padding: 0 0 rem(10px) rem(16px);
  font-size: rem(11px);
  max-width: rem(1280px);

  &--inbound {
    padding: 0 0 rem(19px) rem(19px);
  }

  &__title {
    text-transform: uppercase;
    font-weight: 600;
    position: relative;
    margin: 0 0 rem(7px) 0;
  }

  &__title--actions {
    display: flex;
    align-items: flex-end;
    margin: 0;
    height: rem(18px);
  }

  &__main_list {
    list-style-type: none;
    padding: 0;
  }

  &__main_list_item {
    padding: 0 rem(10px) rem(30px) rem(13px);
    width: 20%;
    min-width: 160px;
  }

  &__main_list_item:first-child {
    padding: 0 rem(10px) rem(30px) rem(30px);
  }

  &__main_list_item--reconversion {
    width: 20%;
  }

  &__main_list_item_inner {
    width: 100%;
  }

  &__main_list_item:first-child {
    margin: 0;
  }

  &__icon {
    position: absolute;
    content: '';
    width: rem(16px);
    height: rem(16px);
    top: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
  }

  &__icon--customer {
    left: rem(-18px);
  }

  &__icon--actions {
    background-position: rem(-15px) 0;
    left: rem(-21px);
  }

  &__icon--shipping {
    background-position: rem(-68px) 0;
    width: rem(18px);
    left: rem(-23px);
  }

  &__icon--refund {
    background-position: rem(-32px) 0;
    left: rem(-21px);
  }

  &__icon--reconversion {
    background-position: rem(-50px) 0;
    left: rem(-21px);
  }

  &__list {
    padding: rem(5px) 0 0 0;
    margin: 0;
    list-style-type: none;
  }

  &__list_item {
    margin: 0 0 rem(7px) 0;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      clear: both;

    }
  }

  &__list_item--parcel {
    margin: 0 0 rem(2px) 0;
    text-decoration: underline;
    font-style: italic;
  }

  &__list_item_title,
  &__list_item_description {
    white-space: nowrap;
  }

  &__list_item_title {
    font-weight: 600;
  }

  &__list_item_description {
    padding: 0 0 0 rem(3px);
    float: right
  }

  &__list_item_description--sublist {
    float: none;
    font-size: rem(10px);
  }

  &__list_item_description--shipment_status {
    float: none;
  }

  &__list_item_description--right {
    text-align: right;
  }

  &__list_item_description--logtrade {
    white-space: inherit;
  }

  &__sublist {
    padding: 0;
    list-style-type: none;
  }

  &__sublist_item {
    margin: 0 0 rem(5px) 0;
  }

  &__title_status {
    display: inline-block;
    padding: rem(2px);
    border-radius: rem(5px);
    text-transform: uppercase;
    font-weight: normal;
    font-size: rem(10px);
    color: $main-color;
  }

  &__sublist_item_article_id {
    margin: 0;
  }

  &__sublist_item_article_id_push {
    visibility: hidden;
  }

  &__external_status {
    position: relative;
    margin-top: rem(5px);
  }

  &__flex_breaker {
    flex-basis: 100%;
    height: 0;
  }

  &__tooltip {
    position: absolute;
    display: inline-block;
    margin-left: rem(25px);
    height: 100%;
    top: -2px;
    right: -25px;
  }

  &__tooltip_icon {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: rem(-9px);
  }

  &__return_fixed_fee {
    border-top: rem(1px) solid;
    clear: both;
  }

  &__reconversion_item_data {

  }

  &__reconversion_item_name {

  }

  &__reconversion_item_attr {
    display: block;
    width: 100%;
  }

  &__reconversion_item_attr--with_push {
    font-size: rem(9px);
    padding: 0 0 0 rem(3px);
    margin: rem(2px) 0;
    font-style: italic;
    height: rem(1px);
  }

  &__log_icon {
    position: relative;
    cursor: pointer;
    fill: $F500 !important;
  }

}

@media (max-width: 1730px) {
  .b-return-description {
    &__list_item_title,
    &__list_item_description {
      white-space: normal;
      //font-size: 9px;
    }
    &__list_item_description {
      float: none
    }
  }
}